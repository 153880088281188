import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { LoadingMessage } from '../../core/components/LoadingMessage/LoadingMessage';
import NotAuthorisedPage from '../../core/components/NotAuthorisedPage/NotAuthorisedPage';
import type { ReduxState } from '../../rootReducer';
import * as actions from '../redux/actions';
import CurrentUserSelectors from '../redux/selectors';

type OwnProps = {
  children: React.ReactNode;
};
type StateProps = {
  currentUserLevel?: number;
  isFetching: boolean;
};

type Props = PropsFromRedux & OwnProps;

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getUserLevel: bindActionCreators(actions.getUserLevel, dispatch),
});

const mapStateToProps = (state: ReduxState): StateProps => ({
  isFetching: CurrentUserSelectors.isFetchingCurrentUser(state),
  currentUserLevel: CurrentUserSelectors.getCurrentUserLevel(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class UserRoleLevelLoader extends React.Component<Props> {
  static defaultProps = {
    currentUserLevel: undefined,
  };

  componentDidMount() {
    const { currentUserLevel, getUserLevel } = this.props;
    if (!currentUserLevel) {
      getUserLevel();
    }
  }

  render() {
    const { children, currentUserLevel, isFetching } = this.props;

    if (!isFetching && currentUserLevel == null) {
      return <NotAuthorisedPage />;
    }
    if (currentUserLevel) {
      return children;
    }
    if (isFetching) {
      return (
        <div
          className="col span_12_of_12"
          style={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoadingMessage />
        </div>
      );
    }
    return null;
  }
}

export default connector(UserRoleLevelLoader);
