import React, { PureComponent } from 'react';
import { MsalContext } from '@azure/msal-react';
import { connect, ConnectedProps } from 'react-redux';
import { ReportStatuses } from '../../core/constants';
import { reportTypes } from '../../../core';
import { styles as commonStyles } from '../style';
import DownloadReport from './DownloadReport';
import ReportPageButton from './ReportPageButton';
import CurrentUserSelectors from '../../../currentUser/redux/selectors';
import { UserLevel, UserLevelNumber } from '../../../currentUser/constants';
import type { ReportStatus } from '../../types';

import type { ReduxState } from '../../../rootReducer';
import { ApiSavedReportMinimal } from '../../types/api';
import Selectors from '../../../patient/core/redux/selectors';

type OwnProps = {
  isNewReport: boolean;
  handleStatusUpdate: (status: ReportStatus, isSelfAuthorised: boolean | undefined) => void;
  reportStatus: ReportStatus;
  reportType: string | null;
  // eslint-disable-next-line react/no-unused-prop-types
  patientId: string;
  isAuthorCurrentUser: boolean;
};
type StateProps = {
  userLevel?: UserLevel;
  patientSavedReports: ApiSavedReportMinimal[];
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & OwnProps;

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  userLevel: CurrentUserSelectors.getCurrentUserLevel(state),
  patientSavedReports: Selectors.getPatientsSavedReports(state, ownProps.patientId),
});

const styles = {
  ...commonStyles,
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
};

class UpdateReportStatus extends PureComponent<Props> {
  static defaultProps = {
    userLevel: undefined,
    patientSavedReports: [],
  };

  render() {
    return <div>{this.renderUpdateStatusButtons()}</div>;
  }

  static contextType = MsalContext;

  renderUpdateStatusButtons = () => {
    const { isNewReport, reportStatus, reportType } = this.props;

    if (isNewReport) {
      return null;
    }

    if (reportStatus === ReportStatuses.Draft) {
      return reportType === reportTypes.internal ? (
        <div style={styles.flexRow}>
          <div style={{ marginRight: '15px' }}>
            {this.renderUpdateStatusButton('Send for Authorisation', ReportStatuses.AwaitingAuthorisation, undefined)}
          </div>
          {this.showUkFinaliseButton() && this.renderUpdateStatusButton('Finalise', ReportStatuses.Authorised, true)}
        </div>
      ) : (
        this.showInternationalFinaliseButton() &&
          this.renderUpdateStatusButton('Finalise', ReportStatuses.Authorised, true)
      );
    }

    if (reportStatus === ReportStatuses.Authorised) {
      return (
        <div style={styles.flexRow}>
          {this.renderBackToDraftButton(reportType)}
          <div style={{ marginRight: '15px' }}>
            <DownloadReport />
          </div>
          <div style={{ marginRight: '15px' }}>
            {this.renderUpdateStatusButton('Mark As Sent', ReportStatuses.Sent, undefined)}
          </div>
        </div>
      );
    }

    if (reportStatus === ReportStatuses.Sent) {
      return <DownloadReport />;
    }

    return (
      <div style={styles.flexRow}>
        <div style={{ marginRight: '15px' }}>
          {this.showAuthoriseButton() &&
            this.renderUpdateStatusButton(
              'Authorise',
              ReportStatuses.Authorised,
              false,
              this.disableAuthoriseButton(),
              this.disableAuthoriseButton()
                ? 'This report is authored by you, please ask another user to authorise it'
                : undefined
            )}
        </div>
        {this.renderUpdateStatusButton('Back To Draft', ReportStatuses.Draft, undefined)}
      </div>
    );
  };

  showInternationalFinaliseButton = () => {
    const { userLevel } = this.props;
    return userLevel != null && userLevel >= UserLevelNumber.LevelTwo;
  };

  showUkFinaliseButton = () => {
    const { userLevel } = this.props;
    return userLevel != null && userLevel >= UserLevelNumber.LevelThree;
  };

  showAuthoriseButton = () => {
    const { userLevel } = this.props;
    return userLevel != null && userLevel >= UserLevelNumber.LevelFour;
  };

  disableAuthoriseButton = () => {
    const { userLevel, isAuthorCurrentUser } = this.props;

    return isAuthorCurrentUser && (userLevel == null || userLevel < UserLevelNumber.LevelSix);
  };

  renderUpdateStatusButton = (
    buttonName: string,
    newStatus: string,
    newSelfAuthorised: boolean | undefined,
    disabled?: boolean,
    tooltip?: string | undefined
  ) => (
    <ReportPageButton
      buttonName={buttonName}
      handleButtonClick={() => this.updateReportStatus(newStatus, newSelfAuthorised)}
      newStatus={newStatus}
      disabled={disabled}
      tooltip={tooltip}
    />
  );

  renderBackToDraftButton = (reportType?: string | null) =>
    reportType === reportTypes.international || reportType === reportTypes.internal ? (
      <div style={{ marginRight: '15px' }}>
        {this.renderUpdateStatusButton('Back To Draft', ReportStatuses.Draft, false)}
      </div>
    ) : null;

  updateReportStatus = (status: ReportStatus, isSelfAuthorised: boolean | undefined) => {
    const { handleStatusUpdate } = this.props;
    return handleStatusUpdate(status, isSelfAuthorised);
  };
}

const connector = connect(mapStateToProps);

export default connector(UpdateReportStatus);
