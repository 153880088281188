import {
  ErrorAction as ApiErrorAction,
  get,
  post,
  put,
  Request as ApiRequest,
  RequestAction as ApiRequestAction,
  Response,
  SuccessAction as ApiSuccessAction,
} from '@an/nova-frontend-rest-client';
import UrlAssembler from 'url-assembler';
import { Moment } from 'moment';
// eslint-disable-next-line import/no-cycle
import { fetchPatientById, getPatientDetails } from '../../patientSearch/redux/actions';
import Config from '../../../config';
import { getPatientSearchRequest } from '../../../donorMatchSearchRequests';
import { getPatientSearchReports } from '../../../searchReports';

import type { AlgorithmDataType, AlgorithmType, CreateSearchRequest, EmdisSearchRequest } from '../../types';
import type { Dispatch } from '../../../core/types';
import type { ReduxState } from '../../../rootReducer';
import type { SearchType } from '../../../core/constants/searchTypes';
import { ApiHlaSummary, TextMessage } from '../types';

const GET_AVAILABLE_ALGORITHMS: 'algorithm' = 'algorithm';
const GET_AVAILABLE_ALGORITHMS_ERROR: 'algorithm/error' = 'algorithm/error';
const GET_AVAILABLE_ALGORITHMS_REQUEST: 'algorithm/request' = 'algorithm/request';
const GET_AVAILABLE_ALGORITHMS_SUCCESS: 'algorithm/success' = 'algorithm/success';
const PATIENT_CREATE_EMDIS_SEARCH_REQUEST: 'patient/emdisSearchRequestInitiation/create' =
  'patient/emdisSearchRequestInitiation/create';
const PATIENT_CREATE_EMDIS_SEARCH_REQUEST_ERROR: 'patient/emdisSearchRequestInitiation/create/error' =
  'patient/emdisSearchRequestInitiation/create/error';
const PATIENT_CREATE_EMDIS_SEARCH_REQUEST_REQUEST: 'patient/emdisSearchRequestInitiation/create/request' =
  'patient/emdisSearchRequestInitiation/create/request';
const PATIENT_CREATE_EMDIS_SEARCH_REQUEST_SUCCESS: 'patient/emdisSearchRequestInitiation/create/success' =
  'patient/emdisSearchRequestInitiation/create/success';
const PATIENT_CREATE_SEARCH_REQUEST: 'patient/searchRequestInitiation/create' =
  'patient/searchRequestInitiation/create';
const PATIENT_CREATE_SEARCH_REQUEST_ERROR: 'patient/searchRequestInitiation/create/error' =
  'patient/searchRequestInitiation/create/error';
const PATIENT_CREATE_SEARCH_REQUEST_REQUEST: 'patient/searchRequestInitiation/create/request' =
  'patient/searchRequestInitiation/create/request';
const PATIENT_CREATE_SEARCH_REQUEST_SUCCESS: 'patient/searchRequestInitiation/create/success' =
  'patient/searchRequestInitiation/create/success';

const ACTIVATE_REPEAT_SEARCH_REQUEST: 'patient/repeatSearchRequest' = 'patient/repeatSearchRequest';
const ACTIVATE_REPEAT_SEARCH_REQUEST_REQUEST: 'patient/repeatSearchRequest/request' =
  'patient/repeatSearchRequest/request';
const ACTIVATE_REPEAT_SEARCH_REQUEST_SUCCESS: 'patient/repeatSearchRequest/success' =
  'patient/repeatSearchRequest/success';
const ACTIVATE_REPEAT_SEARCH_REQUEST_ERROR: 'patient/repeatSearchRequest/error' = 'patient/repeatSearchRequest/error';

const REACTIVATE_REPEAT_SEARCH_REQUEST: 'patient/repeatSearchRequest/reactivate' =
  'patient/repeatSearchRequest/reactivate';
const REACTIVATE_REPEAT_SEARCH_REQUEST_REQUEST: 'patient/repeatSearchRequest/reactivate/request' =
  'patient/repeatSearchRequest/reactivate/request';
const REACTIVATE_REPEAT_SEARCH_REQUEST_SUCCESS: 'patient/repeatSearchRequest/reactivate/success' =
  'patient/repeatSearchRequest/reactivate/success';
const REACTIVATE_REPEAT_SEARCH_REQUEST_ERROR: 'patient/repeatSearchRequest/reactivate/error' =
  'patient/repeatSearchRequest/reactivate/error';

const ACTIVATE_RETRY_FAILED_SEARCH_REQUEST: 'patient/retryFailedSearchRequest' = 'patient/retryFailedSearchRequest';
const ACTIVATE_RETRY_FAILED_SEARCH_REQUEST_REQUEST: 'patient/retryFailedSearchRequest/request' =
  'patient/retryFailedSearchRequest/request';
const ACTIVATE_RETRY_FAILED_SEARCH_REQUEST_SUCCESS: 'patient/retryFailedSearchRequest/success' =
  'patient/retryFailedSearchRequest/success';
const ACTIVATE_RETRY_FAILED_SEARCH_REQUEST_ERROR: 'patient/retryFailedSearchRequest/error' =
  'patient/retryFailedSearchRequest/error';

const ACTIVATE_RETRY_FAILED_REPEAT_SEARCH_REQUEST: 'patient/retryFailedRepeatSearchRequest' =
  'patient/retryFailedRepeatSearchRequest';
const ACTIVATE_RETRY_FAILED_REPEAT_SEARCH_REQUEST_REQUEST: 'patient/retryFailedRepeatSearchRequest/request' =
  'patient/retryFailedRepeatSearchRequest/request';
const ACTIVATE_RETRY_FAILED_REPEAT_SEARCH_REQUEST_SUCCESS: 'patient/retryFailedRepeatSearchRequest/success' =
  'patient/retryFailedRepeatSearchRequest/success';
const ACTIVATE_RETRY_FAILED_REPEAT_SEARCH_REQUEST_ERROR: 'patient/retryFailedRepeatSearchRequest/error' =
  'patient/retryFailedRepeatSearchRequest/error';

const PATIENT_CREATE_SEARCH_REQUEST_CORD_EMDIS_REGISTRIES_UPDATE: 'patient/searchRequestInitiation/cordEmdisRegistries/update' =
  'patient/searchRequestInitiation/cordEmdisRegistries/update';
const PATIENT_CREATE_SEARCH_REQUEST_ALGORITHM_UPDATE: 'patient/searchRequestInitiation/searchAlgorithm/update' =
  'patient/searchRequestInitiation/searchAlgorithm/update';
const PATIENT_CREATE_SEARCH_REQUEST_IS_A_CORD_TOGGLE: 'patient/searchRequestInitiation/isACord/toggle' =
  'patient/searchRequestInitiation/isACord/toggle';
const PATIENT_CREATE_SEARCH_REQUEST_IS_HLA_ADJUSTED_TOGGLE: 'patient/searchRequestInitiation/isHlaAdjusted/toggle' =
  'patient/searchRequestInitiation/isHlaAdjusted/toggle';
const PATIENT_CREATE_SEARCH_REQUEST_REGISTRY_UPDATE: 'patient/searchRequestInitiation/alignedRegistry/update' =
  'patient/searchRequestInitiation/alignedRegistry/update';
const PATIENT_CREATE_SEARCH_REQUEST_DPB1_SCORING_UPDATE: 'patient/searchRequestInitiation/dpb1Scoring/update' =
  'patient/searchRequestInitiation/dpb1Scoring/update';
const PATIENT_CREATE_SEARCH_REQUEST_TYPE_UPDATE: 'patient/searchRequestInitiation/searchType/update' =
  'patient/searchRequestInitiation/searchType/update';

const PATIENT_REPEAT_SEARCH_DATE_UPDATE: 'patient/repeatSearchDate/update' = 'patient/repeatSearchDate/update';
const PATIENT_REPEAT_SEARCH_DATE_UPDATE_SUCCESS: 'patient/repeatSearchDate/update/success' =
  'patient/repeatSearchDate/update/success';
const PATIENT_REPEAT_SEARCH_DATE_UPDATE_ERROR: 'patient/repeatSearchDate/update/error' =
  'patient/repeatSearchDate/update/error';
const PATIENT_REPEAT_SEARCH_DATE_SET: 'patient/repeatSearchDate/set' = 'patient/repeatSearchDate/set';
const FETCH_REPEAT_SEARCH_DATE: 'patient/fetch/repeatSearchDate' = 'patient/fetch/repeatSearchDate';
const FETCH_REPEAT_SEARCH_DATE_ERROR: 'patient/fetch/repeatSearchDate/error' = 'patient/fetch/repeatSearchDate/error';
const FETCH_REPEAT_SEARCH_DATE_REQUEST: 'patient/fetch/repeatSearchDate/request' =
  'patient/fetch/repeatSearchDate/request';
const FETCH_REPEAT_SEARCH_DATE_SUCCESS: 'patient/fetch/repeatSearchDate/success' =
  'patient/fetch/repeatSearchDate/success';

const FETCH_INTERNATIONAL_SEARCH_CREATION_CONSTANTS: 'patient/emdisSearchRequestInitiation/create/constants' =
  'patient/emdisSearchRequestInitiation/create/constants';
const FETCH_INTERNATIONAL_SEARCH_CREATION_CONSTANTS_ERROR: 'patient/emdisSearchRequestInitiation/create/constants/error' =
  'patient/emdisSearchRequestInitiation/create/constants/error';
const FETCH_INTERNATIONAL_SEARCH_CREATION_CONSTANTS_REQUEST: 'patient/emdisSearchRequestInitiation/create/constants/request' =
  'patient/emdisSearchRequestInitiation/create/constants/request';
const FETCH_INTERNATIONAL_SEARCH_CREATION_CONSTANTS_SUCCESS: 'patient/emdisSearchRequestInitiation/create/constants/success' =
  'patient/emdisSearchRequestInitiation/create/constants/success';

const FETCH_PATIENT_HLA_SUMMARY: 'patient/fetch/hla-summary' = 'patient/fetch/hla-summary';
const FETCH_PATIENT_HLA_SUMMARY_REQUEST: 'patient/fetch/hla-summary/request' = 'patient/fetch/hla-summary/request';
const FETCH_PATIENT_HLA_SUMMARY_SUCCESS: 'patient/fetch/hla-summary/success' = 'patient/fetch/hla-summary/success';
const FETCH_PATIENT_HLA_SUMMARY_ERROR: 'patient/fetch/hla-summary/error' = 'patient/fetch/hla-summary/error';

const FETCH_PATIENT_CLOSURE_REASONS: 'patient/fetch/patientClosureReasons' = 'patient/fetch/patientClosureReasons';
const FETCH_PATIENT_CLOSURE_REASONS_ERROR: 'patient/fetch/patientClosureReasons/error' =
  'patient/fetch/patientClosureReasons/error';
const FETCH_PATIENT_CLOSURE_REASONS_REQUEST: 'patient/fetch/patientClosureReasons/request' =
  'patient/fetch/patientClosureReasons/request';
const FETCH_PATIENT_CLOSURE_REASONS_SUCCESS: 'patient/fetch/patientClosureReasons/success' =
  'patient/fetch/patientClosureReasons/success';

const CLOSE_PATIENT: 'patient/close' = 'patient/close';
const CLOSE_PATIENT_REQUEST: 'patient/close/request' = 'patient/close/request';
const CLOSE_PATIENT_SUCCESS: 'patient/close/success' = 'patient/close/success';
const CLOSE_PATIENT_ERROR: 'patient/close/error' = 'patient/close/error';

const OPEN_PATIENT: 'patient/open' = 'patient/open';
const OPEN_PATIENT_REQUEST: 'patient/open/request' = 'patient/open/request';
const OPEN_PATIENT_SUCCESS: 'patient/open/success' = 'patient/open/success';
const OPEN_PATIENT_ERROR: 'patient/open/error' = 'patient/open/error';

const SEND_PATIENT_TO_WMDA: 'patient/sendToWmda' = 'patient/sendToWmda';
const SEND_PATIENT_TO_WMDA_SUCCESS: 'patient/sendToWmda/success' = 'patient/sendToWmda/success';
const SEND_PATIENT_TO_WMDA_ERROR: 'patient/sendToWmda/error' = 'patient/sendToWmda/error';

const GET_PATIENT_TEXT_MESSAGES: 'patient/textMessages' = 'patient/textMessages';
const GET_PATIENT_TEXT_MESSAGES_REQUEST: 'patient/textMessages/request' = 'patient/textMessages/request';
const GET_PATIENT_TEXT_MESSAGES_SUCCESS: 'patient/textMessages/success' = 'patient/textMessages/success';
const GET_PATIENT_TEXT_MESSAGES_ERROR: 'patient/textMessages/error' = 'patient/textMessages/error';

export const Actions = {
  GET_AVAILABLE_ALGORITHMS_ERROR,
  GET_AVAILABLE_ALGORITHMS_REQUEST,
  GET_AVAILABLE_ALGORITHMS_SUCCESS,
  PATIENT_CREATE_EMDIS_SEARCH_REQUEST_ERROR,
  PATIENT_CREATE_EMDIS_SEARCH_REQUEST_REQUEST,
  PATIENT_CREATE_EMDIS_SEARCH_REQUEST_SUCCESS,
  PATIENT_CREATE_SEARCH_REQUEST_ERROR,
  PATIENT_CREATE_SEARCH_REQUEST_REQUEST,
  PATIENT_CREATE_SEARCH_REQUEST_SUCCESS,
  PATIENT_CREATE_SEARCH_REQUEST_CORD_EMDIS_REGISTRIES_UPDATE,
  PATIENT_CREATE_SEARCH_REQUEST_ALGORITHM_UPDATE,
  PATIENT_CREATE_SEARCH_REQUEST_IS_A_CORD_TOGGLE,
  PATIENT_CREATE_SEARCH_REQUEST_IS_HLA_ADJUSTED_TOGGLE,
  PATIENT_CREATE_SEARCH_REQUEST_REGISTRY_UPDATE,
  PATIENT_CREATE_SEARCH_REQUEST_DPB1_SCORING_UPDATE,
  PATIENT_CREATE_SEARCH_REQUEST_TYPE_UPDATE,
  PATIENT_REPEAT_SEARCH_DATE_UPDATE,
  PATIENT_REPEAT_SEARCH_DATE_UPDATE_SUCCESS,
  PATIENT_REPEAT_SEARCH_DATE_UPDATE_ERROR,
  PATIENT_REPEAT_SEARCH_DATE_SET,
  ACTIVATE_REPEAT_SEARCH_REQUEST,
  ACTIVATE_REPEAT_SEARCH_REQUEST_REQUEST,
  ACTIVATE_REPEAT_SEARCH_REQUEST_ERROR,
  ACTIVATE_REPEAT_SEARCH_REQUEST_SUCCESS,
  REACTIVATE_REPEAT_SEARCH_REQUEST,
  REACTIVATE_REPEAT_SEARCH_REQUEST_REQUEST,
  REACTIVATE_REPEAT_SEARCH_REQUEST_SUCCESS,
  REACTIVATE_REPEAT_SEARCH_REQUEST_ERROR,
  ACTIVATE_RETRY_FAILED_SEARCH_REQUEST,
  ACTIVATE_RETRY_FAILED_SEARCH_REQUEST_REQUEST,
  ACTIVATE_RETRY_FAILED_SEARCH_REQUEST_SUCCESS,
  ACTIVATE_RETRY_FAILED_SEARCH_REQUEST_ERROR,
  ACTIVATE_RETRY_FAILED_REPEAT_SEARCH_REQUEST,
  ACTIVATE_RETRY_FAILED_REPEAT_SEARCH_REQUEST_REQUEST,
  ACTIVATE_RETRY_FAILED_REPEAT_SEARCH_REQUEST_SUCCESS,
  ACTIVATE_RETRY_FAILED_REPEAT_SEARCH_REQUEST_ERROR,
  FETCH_REPEAT_SEARCH_DATE,
  FETCH_REPEAT_SEARCH_DATE_REQUEST,
  FETCH_REPEAT_SEARCH_DATE_SUCCESS,
  FETCH_REPEAT_SEARCH_DATE_ERROR,
  FETCH_INTERNATIONAL_SEARCH_CREATION_CONSTANTS_REQUEST,
  FETCH_INTERNATIONAL_SEARCH_CREATION_CONSTANTS_SUCCESS,
  FETCH_INTERNATIONAL_SEARCH_CREATION_CONSTANTS_ERROR,
  FETCH_PATIENT_HLA_SUMMARY_REQUEST,
  FETCH_PATIENT_HLA_SUMMARY_SUCCESS,
  FETCH_PATIENT_HLA_SUMMARY_ERROR,
  FETCH_PATIENT_CLOSURE_REASONS,
  FETCH_PATIENT_CLOSURE_REASONS_ERROR,
  FETCH_PATIENT_CLOSURE_REASONS_REQUEST,
  FETCH_PATIENT_CLOSURE_REASONS_SUCCESS,
  CLOSE_PATIENT,
  CLOSE_PATIENT_REQUEST,
  CLOSE_PATIENT_ERROR,
  CLOSE_PATIENT_SUCCESS,
  SEND_PATIENT_TO_WMDA,
  SEND_PATIENT_TO_WMDA_ERROR,
  SEND_PATIENT_TO_WMDA_SUCCESS,
  OPEN_PATIENT_REQUEST,
  OPEN_PATIENT_SUCCESS,
  OPEN_PATIENT_ERROR,
  GET_PATIENT_TEXT_MESSAGES_REQUEST,
  GET_PATIENT_TEXT_MESSAGES_SUCCESS,
  GET_PATIENT_TEXT_MESSAGES_ERROR,
};

type PatientIdParam = { patientId: string };
type ErrorMessageParam = { errorMessage: string };
type SuccessMessageParam = { successMessage: string };
type Error = { Error: string };

export type GetAvailableAlgorithmsRequestAction = ApiErrorAction<
  typeof GET_AVAILABLE_ALGORITHMS_REQUEST,
  Record<string, unknown>
>;

export type GetAvailableAlgorithmsErrorAction = ApiErrorAction<
  typeof GET_AVAILABLE_ALGORITHMS_ERROR,
  Record<string, unknown>
>;

export type GetAvailableAlgorithmsSuccessAction = ApiSuccessAction<
  typeof GET_AVAILABLE_ALGORITHMS_SUCCESS,
  Record<string, unknown>,
  AlgorithmDataType[]
>;

export type PatientCreateEmdisSearchRequestErrorAction = ApiErrorAction<
  typeof PATIENT_CREATE_EMDIS_SEARCH_REQUEST_ERROR,
  ErrorMessageParam & SuccessMessageParam
>;

export type PatientCreateEmdisSearchRequestRequestAction = ApiRequestAction<
  typeof PATIENT_CREATE_EMDIS_SEARCH_REQUEST_REQUEST,
  Record<string, unknown>
>;

export type PatientCreateEmdisSearchRequestSuccessAction = ApiSuccessAction<
  typeof PATIENT_CREATE_EMDIS_SEARCH_REQUEST_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  string[]
>;

export type PatientCreateSearchRequestRequestAction = ApiErrorAction<
  typeof PATIENT_CREATE_SEARCH_REQUEST_REQUEST,
  Record<string, unknown>
>;

export type PatientCreateSearchRequestErrorAction = ApiErrorAction<
  typeof PATIENT_CREATE_SEARCH_REQUEST_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Error
>;

export type PatientCreateSearchRequestSuccessAction = ApiSuccessAction<
  typeof PATIENT_CREATE_SEARCH_REQUEST_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  string[] & SuccessMessageParam
>;

export type PatientFetchRepeatSearchDateSuccessAction = ApiSuccessAction<
  typeof FETCH_REPEAT_SEARCH_DATE_SUCCESS,
  PatientIdParam,
  Moment | null | undefined
>;

export type FetchInternationalSearchCreationConstantsSuccessAction = ApiSuccessAction<
  typeof FETCH_INTERNATIONAL_SEARCH_CREATION_CONSTANTS_SUCCESS,
  Record<string, unknown>,
  string[]
>;

export type FetchPatientHlaSummaryRequestAction = {
  type: typeof FETCH_PATIENT_HLA_SUMMARY_REQUEST;
  payload: ApiHlaSummary;
};

export type FetchPatientHlaSummaryErrorAction = ApiErrorAction<
  typeof FETCH_PATIENT_HLA_SUMMARY_ERROR,
  ErrorMessageParam,
  Error
>;

export type FetchPatientHlaSummarySuccessAction = ApiSuccessAction<
  typeof FETCH_PATIENT_HLA_SUMMARY_SUCCESS,
  ErrorMessageParam,
  ApiHlaSummary
>;

export type PatientRepeatSearchDateUpdateSuccessAction = ApiSuccessAction<
  typeof PATIENT_REPEAT_SEARCH_DATE_UPDATE_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  SuccessMessageParam
>;

export type PatientRepeatSearchDateUpdateError = ApiErrorAction<
  typeof PATIENT_REPEAT_SEARCH_DATE_UPDATE_ERROR,
  PatientIdParam & ErrorMessageParam,
  Error
>;

export type PatientCreateSearchRequestAlgorithmUpdateAction = {
  type: typeof PATIENT_CREATE_SEARCH_REQUEST_ALGORITHM_UPDATE;
  payload: string[];
};

export type PatientCreateSearchRequestCordEmdisRegistriesUpdateAction = {
  type: typeof PATIENT_CREATE_SEARCH_REQUEST_CORD_EMDIS_REGISTRIES_UPDATE;
  payload: string[];
};

export type PatientCreateSearchRequestRegistryUpdateAction = {
  type: typeof PATIENT_CREATE_SEARCH_REQUEST_REGISTRY_UPDATE;
  payload: boolean;
};

export type PatientCreateSearchRequestDpb1ScoringUpdateAction = {
  type: typeof PATIENT_CREATE_SEARCH_REQUEST_DPB1_SCORING_UPDATE;
  payload: boolean;
};
export type PatientCreateSearchRequestTypeUpdateAction = {
  type: typeof PATIENT_CREATE_SEARCH_REQUEST_TYPE_UPDATE;
  payload: SearchType;
};
export type PatientCreateSearchRequestIsACordAction = {
  type: typeof PATIENT_CREATE_SEARCH_REQUEST_IS_A_CORD_TOGGLE;
};
export type PatientCreateSearchRequestIsHlaAdjustedAction = {
  type: typeof PATIENT_CREATE_SEARCH_REQUEST_IS_HLA_ADJUSTED_TOGGLE;
  payload: boolean;
};

export type PatientSetRepeatSearchDate = {
  type: typeof PATIENT_REPEAT_SEARCH_DATE_SET;
  payload: {
    date: Moment;
    patientId: string;
  };
};

export type SendPatientToWmdaAction = {
  type: typeof SEND_PATIENT_TO_WMDA;
  payload: {
    data: { successMessage: string };
    params: { successMessage: string };
  };
};

export type SendPatientToWmdaErrorAction = {
  type: typeof SEND_PATIENT_TO_WMDA_ERROR;
  payload: {
    params: { errorMessage?: string };
    response: { body: { Error: string } };
  };
};

export type SendPatientToWmdaSuccessAction = {
  type: typeof SEND_PATIENT_TO_WMDA_SUCCESS;
  payload: {
    data: { successMessage: string };
    params: { successMessage: string };
  };
};

export type FetchPatientClosureReasonsErrorAction = {
  type: typeof FETCH_PATIENT_CLOSURE_REASONS_ERROR;
  payload: { Error: string };
};

export type FetchPatientClosureReasonsSuccessAction = ApiSuccessAction<
  typeof FETCH_PATIENT_CLOSURE_REASONS_SUCCESS,
  Record<string, unknown>,
  string[]
>;
export type FetchPatientClosureReasonsRequestAction = ApiRequestAction<
  typeof FETCH_PATIENT_CLOSURE_REASONS_REQUEST,
  Record<string, unknown>
>;

export type ClosePatientErrorAction = {
  type: typeof CLOSE_PATIENT_ERROR;
  payload: {
    params: {
      errorMessage?: string;
    };
    response: { body: { Error: string } };
  };
};
export type ClosePatientRequestAction = ApiRequestAction<typeof CLOSE_PATIENT_REQUEST, Record<string, unknown>>;
export type ClosePatientSuccessAction = ApiSuccessAction<
  typeof CLOSE_PATIENT_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  string[] & SuccessMessageParam
>;

export type OpenPatientErrorAction = {
  type: typeof OPEN_PATIENT_ERROR;
  payload: {
    params: {
      errorMessage?: string;
    };
    response: { body: { Error: string } };
  };
};
export type OpenPatientSuccessAction = ApiSuccessAction<
  typeof OPEN_PATIENT_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  SuccessMessageParam
>;

export type OpenPatientRequestAction = { type: typeof OPEN_PATIENT_REQUEST; payload: Record<string, unknown> };

export type GetPatientTextMessagesRequestAction = ApiRequestAction<
  typeof GET_PATIENT_TEXT_MESSAGES_REQUEST,
  TextMessage[]
>;
export type GetPatientTextMessagesSuccessAction = ApiSuccessAction<
  typeof GET_PATIENT_TEXT_MESSAGES_SUCCESS,
  ErrorMessageParam,
  TextMessage[]
>;
export type GetPatientTextMessagesErrorAction = ApiErrorAction<
  typeof GET_PATIENT_TEXT_MESSAGES_ERROR,
  ErrorMessageParam,
  Error
>;

export type PatientRepeatRequestAction = ApiErrorAction<typeof ACTIVATE_REPEAT_SEARCH_REQUEST, Record<string, unknown>>;

export type PatientActivateRepeatRequestRequestAction = ApiRequestAction<
  typeof ACTIVATE_REPEAT_SEARCH_REQUEST_REQUEST,
  Record<string, unknown>
>;

export type PatientActivateRepeatRequestErrorAction = ApiErrorAction<
  typeof ACTIVATE_REPEAT_SEARCH_REQUEST_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Error
>;

export type PatientActivateRepeatRequestSuccessAction = ApiSuccessAction<
  typeof ACTIVATE_REPEAT_SEARCH_REQUEST_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  string[] & SuccessMessageParam
>;

export type PatientReactivateRepeatRequestAction = ApiErrorAction<
  typeof REACTIVATE_REPEAT_SEARCH_REQUEST,
  Record<string, unknown>
>;

export type PatientReactivateRepeatRequestRequestAction = ApiRequestAction<
  typeof REACTIVATE_REPEAT_SEARCH_REQUEST_REQUEST,
  Record<string, unknown>
>;

export type PatientReactivateRepeatRequestErrorAction = ApiErrorAction<
  typeof REACTIVATE_REPEAT_SEARCH_REQUEST_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Error
>;

export type PatientReactivateRepeatRequestSuccessAction = ApiSuccessAction<
  typeof REACTIVATE_REPEAT_SEARCH_REQUEST_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  string[] & SuccessMessageParam
>;

export type PatientRetryFailedSearchRequestAction = ApiErrorAction<
  typeof ACTIVATE_RETRY_FAILED_SEARCH_REQUEST,
  Record<string, unknown>
>;

export type PatientRetryFailedSearchRequestRequestAction = ApiRequestAction<
  typeof ACTIVATE_RETRY_FAILED_SEARCH_REQUEST_REQUEST,
  Record<string, unknown>
>;

export type PatientRetryFailedSearchRequestErrorAction = ApiErrorAction<
  typeof ACTIVATE_RETRY_FAILED_SEARCH_REQUEST_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Error
>;

export type PatientRetryFailedSearchRequestSuccessAction = ApiSuccessAction<
  typeof ACTIVATE_RETRY_FAILED_SEARCH_REQUEST_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  string[] & SuccessMessageParam
>;

export type PatientRetryFailedRepeatSearchRequestAction = ApiErrorAction<
  typeof ACTIVATE_RETRY_FAILED_REPEAT_SEARCH_REQUEST,
  Record<string, unknown>
>;

export type PatientRetryFailedRepeatSearchRequestRequestAction = ApiRequestAction<
  typeof ACTIVATE_RETRY_FAILED_REPEAT_SEARCH_REQUEST_REQUEST,
  Record<string, unknown>
>;

export type PatientRetryFailedRepeatSearchRequestErrorAction = ApiErrorAction<
  typeof ACTIVATE_RETRY_FAILED_REPEAT_SEARCH_REQUEST_ERROR,
  ErrorMessageParam & SuccessMessageParam,
  Error
>;

export type PatientRetryFailedRepeatSearchRequestSuccessAction = ApiSuccessAction<
  typeof ACTIVATE_RETRY_FAILED_REPEAT_SEARCH_REQUEST_SUCCESS,
  ErrorMessageParam & SuccessMessageParam,
  string[] & SuccessMessageParam
>;

export const updateIncludeAlignedRegistry = (
  includeAlignedRegistries: boolean
): PatientCreateSearchRequestRegistryUpdateAction => ({
  type: PATIENT_CREATE_SEARCH_REQUEST_REGISTRY_UPDATE,
  payload: includeAlignedRegistries,
});

export const updateIncludeDpb1InScoring = (
  includeDpb1InScoring: boolean
): PatientCreateSearchRequestDpb1ScoringUpdateAction => ({
  type: PATIENT_CREATE_SEARCH_REQUEST_DPB1_SCORING_UPDATE,
  payload: includeDpb1InScoring,
});

export const updateSearchRequestType = (requestType: SearchType): PatientCreateSearchRequestTypeUpdateAction => ({
  type: PATIENT_CREATE_SEARCH_REQUEST_TYPE_UPDATE,
  payload: requestType,
});

export const updateSearchAlgorithm = (algorithms: string[]): PatientCreateSearchRequestAlgorithmUpdateAction => ({
  type: PATIENT_CREATE_SEARCH_REQUEST_ALGORITHM_UPDATE,
  payload: algorithms,
});

export const updateEmdisRegistries = (
  emdisRegistries: string[]
): PatientCreateSearchRequestCordEmdisRegistriesUpdateAction => ({
  type: PATIENT_CREATE_SEARCH_REQUEST_CORD_EMDIS_REGISTRIES_UPDATE,
  payload: emdisRegistries,
});

export const toggleIsACord = () => ({
  type: PATIENT_CREATE_SEARCH_REQUEST_IS_A_CORD_TOGGLE,
});

export const toggleIsHlaAdjusted = (isHlaAdjusted: boolean) => ({
  type: Actions.PATIENT_CREATE_SEARCH_REQUEST_IS_HLA_ADJUSTED_TOGGLE,
  payload: isHlaAdjusted,
});

const setRepeatSearchDate = (date: Moment, patientId: string) => ({
  type: PATIENT_REPEAT_SEARCH_DATE_SET,
  payload: {
    date,
    patientId,
  },
});

export const fetchRepeatSearchDate =
  (patientId: string) =>
  (dispatch: Dispatch<any>): Promise<Response<any>> =>
    dispatch(
      get(`${Config().apiBaseUrl}search-reports/patient/${patientId}/reporting-reset-date`, FETCH_REPEAT_SEARCH_DATE, {
        patientId,
      })
    );

export const updateRepeatSearchDate = (date: Moment, patientId: string) => async (dispatch: Dispatch<any>) => {
  const dateObject = { Time: date };
  const url = UrlAssembler(Config().apiBaseUrl)
    .template(`/search-reports/patient/${patientId}/reporting-reset-date`)
    .toString();
  const req = await dispatch(
    post(
      url,
      PATIENT_REPEAT_SEARCH_DATE_UPDATE,
      {
        successMessage: 'Re-search date updated',
        errorMessage: 'Failed to update re-search date',
      },
      dateObject
    )
  );
  if (req.response.ok) {
    dispatch(setRepeatSearchDate(date, patientId));
  }
};

const getAvailableAlgorithms = (): ApiRequest<ReduxState, AlgorithmType[]> => (dispatch) =>
  dispatch(get(`${Config().apiBaseUrl}algorithms`, GET_AVAILABLE_ALGORITHMS));

const getAlgorithmsAndSearchRequests = (patientId: string) => (dispatch: Dispatch<any>) =>
  Promise.all([dispatch(getPatientSearchRequest(patientId)), dispatch(getAvailableAlgorithms())]);

export const createSearchRequest =
  (searchRequestOptions: CreateSearchRequest) =>
  async (dispatch: Dispatch<any>): Promise<string | void> => {
    const url = UrlAssembler(Config().apiBaseUrl).template('search-requests').toString();

    const newRequest = await dispatch(
      post(
        url,
        PATIENT_CREATE_SEARCH_REQUEST,
        {
          successMessage: 'Search request complete',
          errorMessage: 'Search request failed',
        },
        searchRequestOptions
      )
    );

    if (newRequest.response.ok) {
      dispatch(getAlgorithmsAndSearchRequests(searchRequestOptions.patientId));
      return undefined;
    }
    return `Search request failed - ${newRequest.response.status} - ${newRequest.response.body.Error}`;
  };

export const createEmdisSearchRequest =
  (searchRequestOptions: EmdisSearchRequest) =>
  async (dispatch: Dispatch<any>): Promise<string | void> => {
    const url = UrlAssembler(Config().apiBaseUrl).template('search-requests/emdis').toString();

    const newRequest = await dispatch(
      post(
        url,
        PATIENT_CREATE_EMDIS_SEARCH_REQUEST,
        {
          successMessage: 'Search request complete',
          errorMessage: 'Search request failed',
        },
        searchRequestOptions
      )
    );

    if (newRequest.response.ok) {
      dispatch(getAlgorithmsAndSearchRequests(searchRequestOptions.patientId));
      return undefined;
    }
    return `Search request failed - ${newRequest.response.status} - ${newRequest.response.body.Error}`;
  };

export const activateRepeatSearch =
  (originalSearchRequestId: string, patientId: string) =>
  async (dispatch: Dispatch<any>): Promise<string | void> => {
    const url = UrlAssembler(Config().apiBaseUrl)
      .template(`search-requests/${originalSearchRequestId}/repeat`)
      .toString();
    const newRequest = await dispatch(
      post(
        url,
        ACTIVATE_REPEAT_SEARCH_REQUEST,
        {
          successMessage: 'Repeat search activated',
          errorMessage: 'Repeat search activation failed',
        },
        { originalSearchRequestId }
      )
    );

    if (newRequest.response.ok) {
      dispatch(getAlgorithmsAndSearchRequests(patientId));
      return undefined;
    }
    return `Repeat search request failed - ${newRequest.response.status} - ${newRequest.response.body.Error}`;
  };

export const reActivateRepeatSearch =
  (requestId: string, patientId: string) =>
  async (dispatch: Dispatch<any>): Promise<string | void> => {
    const url = `${Config().apiBaseUrl}search-requests/repeat-search/activate?firstRepeatSearchRequestId=${requestId}`;
    const newRequest = await dispatch(
      post(
        url,
        REACTIVATE_REPEAT_SEARCH_REQUEST,
        {
          successMessage: 'Search request has been reactivated',
          errorMessage: 'Repeat search request reactivation failed',
        },
        { requestId }
      )
    );

    if (newRequest.response.ok) {
      dispatch(getAlgorithmsAndSearchRequests(patientId));
      return undefined;
    }
    return `Repeat search request reactivation failed - ${newRequest.response.status} - ${newRequest.response.body.Error}`;
  };

export const retryFailedOriginalSearch =
  (requestId: string, patientId: string, donorType: string) =>
  async (dispatch: Dispatch<any>): Promise<string | void> => {
    const url = `${Config().apiBaseUrl}search-requests/${requestId}/retry?donorType=${donorType}`;
    const newRequest = await dispatch(
      post(
        url,
        ACTIVATE_RETRY_FAILED_SEARCH_REQUEST,
        {
          successMessage: 'Search request has been retried',
          errorMessage: 'Retry of search request failed',
        },
        { requestId, donorType }
      )
    );

    if (newRequest.response.ok) {
      dispatch(getAlgorithmsAndSearchRequests(patientId));
      return undefined;
    }
    return `Retry of search request failed - ${newRequest.response.status} - ${newRequest.response.body.Error}`;
  };

export const retryFailedRepeatSearch =
  (requestId: string, patientId: string, donorType: string) =>
  async (dispatch: Dispatch<any>): Promise<string | void> => {
    const url = `${
      Config().apiBaseUrl
    }search-requests/repeat-search/retry?firstRepeatSearchRequestId=${requestId}&donorType=${donorType}`;
    const newRequest = await dispatch(
      post(
        url,
        ACTIVATE_RETRY_FAILED_REPEAT_SEARCH_REQUEST,
        {
          successMessage: 'Repeat search request has been retried',
          errorMessage: 'Retry of repeat search request failed',
        },
        { requestId }
      )
    );

    if (newRequest.response.ok) {
      dispatch(getAlgorithmsAndSearchRequests(patientId));
      return undefined;
    }
    return `Retry of repeat search request failed - ${newRequest.response.status} - ${newRequest.response.body.Error}`;
  };

export const getPatientDashboardData = (patientId: string) => (dispatch: Dispatch<any>) =>
  Promise.all([
    dispatch(getPatientDetails(patientId)),
    dispatch(getPatientSearchRequest(patientId)),
    dispatch(getPatientSearchReports(patientId)),
    dispatch(getAvailableAlgorithms()),
  ]);

export const fetchInternationalRequestCreationConstants = () => (dispatch: Dispatch<any>) => {
  dispatch(
    get(
      `${Config().apiBaseUrl}search-requests/international/creation-constants`,
      FETCH_INTERNATIONAL_SEARCH_CREATION_CONSTANTS
    )
  );
};

export const sendPatientToWmda =
  (patientId: string) =>
  (dispatch: Dispatch<any>): SendPatientToWmdaAction =>
    dispatch(
      put<ReduxState, ApiRequestAction<string>>(
        `${Config().apiBaseUrl}patients/${patientId}/wmda`,
        SEND_PATIENT_TO_WMDA,
        {
          patientId,
          errorMessage: `Failed to send patient ${patientId} to WMDA`,
          successMessage: `Successfully sent patient ${patientId} to WMDA`,
        },
        {}
      )
    );

export const fetchPatientClosureReasons = () => (dispatch: Dispatch<any>) => {
  const url = `${Config().apiBaseUrl}patients/closure-reasons`;
  dispatch(
    get(url, FETCH_PATIENT_CLOSURE_REASONS, {
      errorMessage: 'Failed to retrieve list of closure reasons',
    })
  );
};

export const closePatient =
  (
    patientId: string,
    closureReason: string,
    causeOfDeath: string | null | undefined,
    dateOfDeath: Moment | null | undefined
  ) =>
  async (dispatch: Dispatch<any>) => {
    const url = `${Config().apiBaseUrl}patients/${patientId}/close`;
    const result = await dispatch(
      put<ReduxState, ApiRequestAction<string>>(
        url,
        CLOSE_PATIENT,
        {
          successMessage: 'Patient closed',
          errorMessage: `Failed to close patient ${patientId}`,
        },
        {
          id: patientId,
          closureReason,
          causeOfDeath,
          dateOfDeath,
        }
      )
    );
    if (result.response.ok) {
      dispatch(fetchPatientById(patientId));
      dispatch(getAlgorithmsAndSearchRequests(patientId));
    }
  };

export const openPatient = (patientId: string) => async (dispatch: Dispatch<any>) => {
  const url = `${Config().apiBaseUrl}patients/${patientId}/open`;
  const result = await dispatch(
    put<ReduxState, ApiRequestAction<string>>(url, OPEN_PATIENT, {
      successMessage: 'Patient opened',
      errorMessage: `Failed to open patient ${patientId}`,
    })
  );
  if (result.response.ok) {
    dispatch(fetchPatientById(patientId));
    dispatch(getAlgorithmsAndSearchRequests(patientId));
  }
};

export const fetchHlaSummary =
  (patientId: string, donorId: string, investigationId: number) => async (dispatch: Dispatch<any>) => {
    const url = `${
      Config().apiBaseUrl
    }external-investigations/patient/${patientId}/hla-summary?donorId=${donorId}&investigationId=${investigationId}`;
    await dispatch(
      get<ReduxState, ApiRequestAction<ErrorMessageParam>>(url, FETCH_PATIENT_HLA_SUMMARY, {
        errorMessage: `Failed to fetch HLA summary for following data: patientId = ${patientId}, donorId = ${donorId}, investigationId = ${investigationId}`,
      })
    );
  };

export const getPatientTextMessages = (patientId: string) => async (dispatch: Dispatch<any>) => {
  const url = `${Config().apiBaseUrl}patients/${patientId}/text-messages`;
  await dispatch(
    get<ReduxState, ApiRequestAction<ErrorMessageParam>>(url, GET_PATIENT_TEXT_MESSAGES, {
      errorMessage: `Failed to fetch Text Messages for following data: patientId = ${patientId}`,
    })
  );
};
