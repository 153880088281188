import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, AnyAction, Dispatch as ReduxDispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import queryString from 'query-string';
import SaveReport from './SaveReport';
import { DetailedPatientDisplay } from '../../../patient';
import UpdateReportStatus from './UpdateReportStatus';
import { CurrentReportSelectors } from '../../core/redux/selectors';
import ReportActionsBar from './ReportActionsBar';
import ReportStatusDisplay from './ReportStatusDisplay';
import ReportCreationTabs from './ReportCreationTabs';
import { ErrorMessage } from '../../../core';
import { getReportType } from '../helpers/getReportType';
import { styles as commonStyles } from '../style';
import { colors } from '../../../style';
import * as Actions from '../redux/actions';
import * as DonorMatchSearchRequests from '../../../donorMatchSearchRequests';
import * as CurrentUserActions from '../../../currentUser/redux/actions';

import type { ReportStatus } from '../../types';
import type { ErrorMessages, RouterMatch } from '../../../core/types';
import type { ReduxState } from '../../../rootReducer';

type QueryParams = {
  reportType: string;
};
type DispatchProps = {
  editSearchReportStatus: typeof Actions.editSearchReportStatus;
  getPatientSearchRequest: typeof DonorMatchSearchRequests.getPatientSearchRequest;
  getReportGenerationPageData: typeof Actions.getReportGenerationPageData;
  getSavedReport: typeof Actions.getSavedReport;
  getUserLevel: typeof CurrentUserActions.getUserLevel;
  resetReportDetails: typeof Actions.resetReportDetails;
  saveSearchReport: typeof Actions.saveSearchReport;
};
type OwnProps = {
  match: RouterMatch;
};
type WithRouterOwnProps = RouteComponentProps & OwnProps;
type StateProps = {
  errorMessages: ErrorMessages;
  hasErrored: boolean;
  isReportInternational?: boolean;
  queryParams: QueryParams;
  reportOnGoing: boolean;
  reportStatus: ReportStatus;
  isSelfAuthorised: boolean | undefined;
  isAuthorCurrentUser: boolean;
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & WithRouterOwnProps;

const mapStateToProps = (state: ReduxState, ownProps: WithRouterOwnProps): StateProps => {
  const queryParams: QueryParams = queryString.parse(ownProps.location.search);
  return {
    isSelfAuthorised: CurrentReportSelectors.isSelfAuthorised(state),
    isReportInternational: CurrentReportSelectors.isReportInternational(state),
    reportStatus: CurrentReportSelectors.getReportStatus(state),
    isAuthorCurrentUser: CurrentReportSelectors.getIsAuthorCurrentUser(state),
    hasErrored: CurrentReportSelectors.hasFetchReportGenerationDataErrored(state),
    errorMessages: CurrentReportSelectors.getAllErrorMessages(state),
    reportOnGoing: CurrentReportSelectors.isReportOnGoing(state),
    queryParams,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>): DispatchProps => ({
  getUserLevel: bindActionCreators(CurrentUserActions.getUserLevel, dispatch),
  getReportGenerationPageData: bindActionCreators(Actions.getReportGenerationPageData, dispatch),
  getSavedReport: bindActionCreators(Actions.getSavedReport, dispatch),
  saveSearchReport: bindActionCreators(Actions.saveSearchReport, dispatch),
  editSearchReportStatus: bindActionCreators(Actions.editSearchReportStatus, dispatch),
  resetReportDetails: bindActionCreators(Actions.resetReportDetails, dispatch),
  getPatientSearchRequest: bindActionCreators(DonorMatchSearchRequests.getPatientSearchRequest, dispatch),
});

const styles = {
  ...commonStyles,
  reportStatusDisplay: {
    borderStyle: 'solid',
    padding: '10px',
    borderColor: colors.ANGreen,
    borderWidth: '1px',
  },
};
class ReportCreationPage extends PureComponent<Props> {
  static defaultProps = {
    isReportInternational: undefined,
  };

  componentDidMount() {
    const {
      getPatientSearchRequest,
      getReportGenerationPageData,
      getSavedReport,
      getUserLevel,
      match,
      reportOnGoing,
      resetReportDetails,
    } = this.props;

    const { patientId, reportId } = match.params;

    getUserLevel();

    if (reportOnGoing && patientId) {
      return getPatientSearchRequest(patientId);
    }

    resetReportDetails();

    if (this.isNewReport() && patientId) {
      return getReportGenerationPageData(patientId);
    }

    if (reportId && patientId) {
      return getSavedReport(reportId, patientId);
    }

    return null;
  }

  render() {
    const {
      errorMessages,
      hasErrored,
      isReportInternational,
      match,
      queryParams,
      reportStatus,
      isSelfAuthorised,
      isAuthorCurrentUser,
    } = this.props;
    const { patientId } = match.params;
    const reportType = this.isNewReport() ? queryParams.reportType : getReportType(isReportInternational);

    return (
      <div style={{ width: '100%' }}>
        <div className="col span_12_of_12">
          <DetailedPatientDisplay patientId={patientId} />
        </div>
        {hasErrored && !patientId ? (
          <div className="col span_12_of_12">
            <ErrorMessage errorMessages={errorMessages} />
          </div>
        ) : (
          <div>
            <div className="col span_12_of_12">
              <ReportActionsBar
                left={
                  <div style={{ display: 'flex' }}>
                    <SaveReport
                      handleSaveReport={this.handleSaveSearchReport}
                      reportStatus={reportStatus}
                      isNewReport={this.isNewReport()}
                    />
                  </div>
                }
                centre={
                  <div style={styles.reportStatusDisplay}>
                    <ReportStatusDisplay status={reportStatus} isSelfAuthorised={isSelfAuthorised} />
                  </div>
                }
                right={
                  <UpdateReportStatus
                    isNewReport={this.isNewReport()}
                    handleStatusUpdate={this.changeSearchReportStatus}
                    reportStatus={reportStatus}
                    reportType={reportType}
                    patientId={patientId}
                    isAuthorCurrentUser={isAuthorCurrentUser}
                  />
                }
              />
              {reportType && (
                <ReportCreationTabs patientId={patientId} reportType={reportType} isNewReport={this.isNewReport()} />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  handleSaveSearchReport = () => {
    const { match, queryParams, saveSearchReport } = this.props;
    const { patientId, reportId } = match.params;
    const { reportType } = queryParams;

    if (patientId && reportId) {
      saveSearchReport(patientId, reportId, reportType);
    }
  };

  changeSearchReportStatus = (newStatus: string, newIsSelfAuthorised: boolean | undefined) => {
    const { editSearchReportStatus, match, queryParams } = this.props;
    const { reportId } = match.params;
    const { reportType } = queryParams;

    if (reportId) {
      editSearchReportStatus(reportId, reportType, newStatus, newIsSelfAuthorised);
    }
  };

  isNewReport = () => {
    const { match } = this.props;
    return match.params.reportId === 'new';
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(ReportCreationPage));
