import _ from 'lodash';
import type { SavedResultSet } from '../../../donorMatchSearchRequests/types';

export const getRegistryDisplayNames = (
  selectedDonorSetIds: string[],
  allSavedDonorSets: { [string: string]: SavedResultSet },
  isRegistryNameUsed: boolean
): (string | undefined)[] =>
  _.uniq(
    selectedDonorSetIds
      .map((setId) =>
        allSavedDonorSets[setId].selectedResults.map((selectedResult) =>
          isRegistryNameUsed ? selectedResult.registryDisplayName : selectedResult.countryName
        )
      )
      .flat()
  );
