import React from 'react';
import UserProfile from '../../users/components/UserProfile';
import NavBar from './NavButtons/NavButtons';
import UserLevel from '../../users/components/UserLevel';

const Header = () => (
  <header id="an-header">
    <div className="nav section group">
      <NavBar />
      <UserProfile />
      <UserLevel />
    </div>
  </header>
);

export default Header;
