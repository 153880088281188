import _ from 'lodash';
import React, { Component, CSSProperties } from 'react';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import queryString from 'query-string';
import * as actions from '../../redux/actions';
import ToggleShownColumnCheckboxes from './ToggleShownColumnCheckboxes';
import AdultFilterToolBar from './Filters/AdultFilterToolBar';
import CordFilterToolBar from './Filters/CordFilterToolBar';
import { PopUpWrapper } from '../../../core/index';
import SavedDonorsList from './SavedDonorsList';
import SelectRange from './SelectRange/SelectRange';
import DonorSelectionDetails from '../SavedSelectionInformation/DonorSelectionDetails';
import ResetResultsButton from '../ResetResultsButton';
import UserRolesSelectors from '../../../currentUser/redux/selectors';
import algorithmTypes from '../../../core/constants/algorithmTypes';
import type { AdultSearchResults, DonorType, SearchResults } from '../../../core/types';
import type { ReduxState } from '../../../rootReducer';
import donorTypes, { donorTypeDisplayString } from '../../../core/constants/donorTypes';
import type { TableContents } from '../ResultsTable/searchResultsTableContents';
import ExpandButton from '../../../core/components/ExpandButton';
import { searchLociCount } from '../../constants/searchLociCount';
import { UserLevel, UserLevelNumber } from '../../../currentUser/constants';

type OwnProps = {
  algorithm?: string;
  donorType: DonorType;
  filteredSearchResults: SearchResults;
  isFetching: boolean;
  registryIds?: string;
  resultSetId: string;
  patientId: string;
  reportId?: string;
  searchResults: SearchResults;
  tableContents: TableContents<any>;
};
type StateProps = {
  userLevel?: UserLevel;
};
type Props = PropsFromRedux & OwnProps & StateProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  userLevel: UserRolesSelectors.getCurrentUserLevel(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  updateMismatchesFilter: bindActionCreators(actions.updateMismatchesFilter, dispatch),
  updateValuesFilter: bindActionCreators(actions.updateValuesFilter, dispatch),
  updateExcludeDkmsUkDonorsFilter: bindActionCreators(actions.updateExcludeDkmsUkDonorsFilter, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const styles: Record<string, CSSProperties> = {
  toolBarComponents: {
    display: 'flex',
  },
  rightToolBarComponents: {
    display: 'flex',
    flexDirection: 'row',
  },
  leftSideButtons: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    marginRight: '25px',
  },
  filterToolBar: {
    width: '850px',
    top: '8%',
  },
};

class ResultSettings extends Component<Props> {
  static defaultProps = {
    algorithm: undefined,
    registryIds: undefined,
    reportId: undefined,
    userLevel: undefined,
  };

  state = {
    isExpanded: true,
  };

  componentDidMount() {
    const {
      algorithm,
      registryIds,
      searchResults,
      updateMismatchesFilter,
      updateExcludeDkmsUkDonorsFilter,
      updateValuesFilter,
    } = this.props;
    const isExternal = algorithm === algorithmTypes.external;
    const query = queryString.parse(window.location.search);
    if (isExternal && !query.setId) {
      // Set Mismatch filter to None (6/6 potential) here to make it the
      // default for external searches if there is no setId defined.
      // We cannot set it when defining initial state in Redux as it would
      // apply to internal searches as well.
      updateMismatchesFilter(null, null, searchLociCount);
    }
    if (searchResults.results.length > 0 && registryIds) {
      // Match registry IDs from query parameter to corresponding registry display names.
      const resultsMatchedToRegistryId = (searchResults as AdultSearchResults).results.filter((result) =>
        registryIds.split(',').includes(result.donor.originatingRegistry.id)
      );
      const parsedRegistryDisplayNames = _.uniq(
        resultsMatchedToRegistryId.map((result) => result.donor.originatingRegistry.displayName)
      );
      updateValuesFilter('originatingRegistryName', parsedRegistryDisplayNames);
    }
    if (isExternal) {
      updateExcludeDkmsUkDonorsFilter(true);
    }
  }

  render() {
    const {
      algorithm,
      reportId,
      patientId,
      donorType,
      filteredSearchResults,
      isFetching,
      resultSetId,
      userLevel,
      searchResults,
      tableContents,
    } = this.props;

    const { isExpanded } = this.state;
    const isExternal = algorithm === algorithmTypes.external;
    const isSolar = algorithm === algorithmTypes.solar;

    const filterToolBar =
      searchResults.type === donorTypes.cord.value ? (
        <CordFilterToolBar />
      ) : (
        <AdultFilterToolBar
          isExternal={isExternal}
          searchResults={searchResults as AdultSearchResults}
          tableContents={tableContents}
        />
      );

    return (
      <>
        <div style={styles.toolBarComponents}>
          <div style={{ marginRight: '3px', display: 'flex' }}>
            <PopUpWrapper name="Columns Shown" placement="right" buttonClassName="btn btn--secondary btn--table">
              <ToggleShownColumnCheckboxes tableContents={tableContents} />
            </PopUpWrapper>
            <PopUpWrapper
              name="Filters"
              placement="right"
              buttonClassName="btn btn--secondary btn--table"
              popUpStyle={styles.filterToolBar}
            >
              {filterToolBar}
            </PopUpWrapper>
            <PopUpWrapper name="Select Range" placement="right" buttonClassName="btn btn--secondary btn--table">
              <SelectRange searchResults={filteredSearchResults} donorType={donorType} />
            </PopUpWrapper>
            <PopUpWrapper
              name={`See Saved ${donorTypeDisplayString(donorType)}s`}
              placement="right"
              buttonClassName="btn btn--secondary btn--table"
            >
              <SavedDonorsList patientId={patientId} reportId={reportId} donorType={donorType} algorithm={algorithm} />
            </PopUpWrapper>
            <ResetResultsButton donorType={donorType} />
            {!isSolar && !isExternal && userLevel != null && userLevel >= UserLevelNumber.LevelFour && (
              <a
                href={`validationResults?resultSetId=${resultSetId}&donorType=${donorType}`}
                className="btn btn--secondary btn--table"
                style={{ width: '100%' }}
              >
                <strong>Validation Results Page</strong>
              </a>
            )}
            <ExpandButton isExpanded={isExpanded} isToggleNotes name="Notes" toggleExpansion={this.toggleExpansion} />
          </div>
        </div>
        {isExpanded
          ? !isFetching && (
              <div style={styles.rightToolBarComponents}>
                <DonorSelectionDetails resultSetId={resultSetId} />
              </div>
            )
          : null}
      </>
    );
  }

  toggleExpansion = (): void => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };
}

export default connector(ResultSettings);
