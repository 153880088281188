import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, AnyAction, Dispatch as ReduxDispatch } from 'redux';
import '../../internationalDonorsTableStyling.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { getRegistryDisplayNames } from '../../../core/helpers/getRegistryDisplayNames';
import donorTypes from '../../../../core/constants/donorTypes';
import { CurrentReportSelectors } from '../../../core/redux/selectors';
import { SearchRequestSelectors } from '../../../../donorMatchSearchRequests';
import type { ReduxState } from '../../../../rootReducer';
import type { SavedResultSet } from '../../../../donorMatchSearchRequests/types';
import type { Registry, CustomRegistry, ReportStatus } from '../../../types';
import RegistryRow from './RegistryRow';
import CustomRegistryRow from './CustomRegistryRow';
import isWhiteSpaceOrEmpty from '../../../../core/helpers/isWhiteSpaceOrEmpty';
import { FeatureFlagsSelectors } from '../../../../featureFlags';

type DispatchProps = {
  updateHiddenState: typeof actions.updateHiddenState;
  handlePendingCheckbox: typeof actions.handlePendingCheckbox;
  updateRegistryNames: typeof actions.updateSelectedRegistryDisplayNames;
  updateCustomRegistries: typeof actions.setCustomRegistries;
  updateSearchedRegistries: typeof actions.updateSearchedRegistries;
  getRegistriesForReport: typeof actions.getRegistriesForReport;
};

type StateProps = {
  customRegistries: CustomRegistry[];
  isReportReadOnly: boolean;
  savedDonorSets: Record<string, SavedResultSet>;
  searchedRegistries: Partial<Registry>[];
  selectedRegistryDisplayNames: (string | undefined)[];
  selectedDonorSetIds: string[];
  reportStatus: ReportStatus;
  searchReportsIsRegistryDisplayNameUsedForNewReports: boolean | undefined;
  isRegistryDisplayNameUsed: boolean;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnProps = {
  hasLoadedRegistries: boolean;
  setHasLoadedRegistries: (hasLoaded: boolean) => void;
};

type Props = PropsFromRedux & RouteComponentProps<{ reportId: string; patientId: string }> & OwnProps;

const mapStateToProps = (state: ReduxState): StateProps => ({
  customRegistries: CurrentReportSelectors.getCustomRegistries(state),
  isReportReadOnly: CurrentReportSelectors.isReportReadOnly(state),
  searchedRegistries: CurrentReportSelectors.getSearchedRegistries(state),
  selectedRegistryDisplayNames: CurrentReportSelectors.getSelectedRegistryDisplayNames(state),
  savedDonorSets: SearchRequestSelectors.getAllSavedResultSets(state, donorTypes.adult.value),
  selectedDonorSetIds: CurrentReportSelectors.getSelectedResultSetIds(state, donorTypes.adult.value),
  reportStatus: CurrentReportSelectors.getReportStatus(state),
  searchReportsIsRegistryDisplayNameUsedForNewReports:
    FeatureFlagsSelectors.getCurrentFeatureFlags(state).searchReportsIsRegistryDisplayNameUsedForNewReports,
  isRegistryDisplayNameUsed: CurrentReportSelectors.getIsRegistryDisplayNameUsed(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>): DispatchProps => ({
  handlePendingCheckbox: bindActionCreators(actions.handlePendingCheckbox, dispatch),
  updateCustomRegistries: bindActionCreators(actions.setCustomRegistries, dispatch),
  updateSearchedRegistries: bindActionCreators(actions.updateSearchedRegistries, dispatch),
  updateRegistryNames: bindActionCreators(actions.updateSelectedRegistryDisplayNames, dispatch),
  updateHiddenState: bindActionCreators(actions.updateHiddenState, dispatch),
  getRegistriesForReport: bindActionCreators(actions.getRegistriesForReport, dispatch),
});

class RegistriesTable extends PureComponent<Props> {
  componentDidMount(): void {
    const {
      searchedRegistries,
      selectedDonorSetIds,
      updateSearchedRegistries,
      getRegistriesForReport,
      match,
      hasLoadedRegistries,
      setHasLoadedRegistries,
      savedDonorSets,
      updateRegistryNames,
      searchReportsIsRegistryDisplayNameUsedForNewReports: featureFlagIsRegistryNameUsed,
      isRegistryDisplayNameUsed: reportIsRegistryNameUsed,
    } = this.props;

    const { patientId, reportId } = match.params;

    const isReportNew = reportId === 'new';

    if (patientId && !hasLoadedRegistries) {
      getRegistriesForReport(selectedDonorSetIds, patientId, isReportNew ? null : reportId);
      setHasLoadedRegistries(true);
    }

    if (Object.keys(savedDonorSets).length > 0) {
      const isRegistryNameUsed = isReportNew ? featureFlagIsRegistryNameUsed ?? false : reportIsRegistryNameUsed;
      const registryNames = getRegistryDisplayNames(selectedDonorSetIds, savedDonorSets, isRegistryNameUsed);
      updateRegistryNames(registryNames);
    }

    const registries = [...searchedRegistries];
    updateSearchedRegistries(registries);
  }

  componentDidUpdate(prevProps: Props): void {
    const {
      savedDonorSets,
      selectedDonorSetIds,
      updateRegistryNames,
      searchReportsIsRegistryDisplayNameUsedForNewReports: featureFlagIsRegistryNameUsed,
      match,
      isRegistryDisplayNameUsed: reportIsRegistryNameUsed,
    } = this.props;

    if (!_.isEqual(prevProps.savedDonorSets, savedDonorSets)) {
      const isReportNew = match.params.reportId === 'new';
      const isRegistryNameUsed = isReportNew ? featureFlagIsRegistryNameUsed ?? false : reportIsRegistryNameUsed;

      const registryNames = getRegistryDisplayNames(selectedDonorSetIds, savedDonorSets, isRegistryNameUsed);
      updateRegistryNames(registryNames);
    }
  }

  render() {
    const {
      customRegistries,
      handlePendingCheckbox,
      isReportReadOnly,
      selectedRegistryDisplayNames,
      searchedRegistries,
    } = this.props;

    return (
      <div>
        <table className="internationalReportRegistries">
          <thead>
            <tr className="border-bottom-solid">
              <th>
                <h2>Registries</h2>
              </th>
              <th>Reporting</th>
              <th>Pending</th>
              <th>Reported</th>
              {!isReportReadOnly && <th>Custom Registry</th>}
            </tr>
          </thead>
          <tbody>
            {searchedRegistries.map((registry: Partial<Registry>) => (
              <RegistryRow
                registry={registry as Registry}
                key={registry.name}
                readOnly={isReportReadOnly}
                isReportingOverride={() =>
                  selectedRegistryDisplayNames.some((c: string | undefined) => c === registry.name)
                }
                onHiddenChange={(isHidden: boolean) =>
                  this.handleHiddenRegistryChange(registry as CustomRegistry, isHidden)
                }
                onPendingChange={(isPending: boolean) =>
                  handlePendingCheckbox(registry.name as string, isPending, this.isNewReport())
                }
              />
            ))}
            {customRegistries.map((registry: CustomRegistry) => (
              <CustomRegistryRow
                registry={registry}
                key={registry.name}
                readOnly={isReportReadOnly}
                onRegistryChange={this.handleCustomRegistryChange}
                onDeleteCustomRegistry={this.deleteCustomRegistry}
              />
            ))}
          </tbody>
        </table>
        {Object.values(customRegistries).some((registry: CustomRegistry) => isWhiteSpaceOrEmpty(registry.name)) && (
          <div className="error-message" data-testid="error">
            <span>Cannot leave registry name blank</span>
          </div>
        )}
        {!isReportReadOnly && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              paddingTop: '30px',
            }}
          >
            <button
              className="btn"
              onClick={this.addCustomRegistry}
              style={{ fontSize: '1.5rem', marginRight: '15px' }}
              type="button"
            >
              + Add another registry
            </button>
          </div>
        )}
      </div>
    );
  }

  deleteCustomRegistry = (registryId: string) => {
    const { customRegistries, searchedRegistries, updateCustomRegistries, updateHiddenState } = this.props;
    const registryToDelete = customRegistries.find((registry) => registry.id === registryId);

    if (registryToDelete) {
      const searchedRegistry = searchedRegistries.find((c: Partial<Registry>) => c.name === registryToDelete.name);

      if (searchedRegistry && searchedRegistry.name) {
        updateHiddenState(searchedRegistry.name, false, this.isNewReport());
      }

      updateCustomRegistries(customRegistries.filter((registry) => registry.id !== registryId));
    }
  };

  addCustomRegistry = () => {
    const { customRegistries, updateCustomRegistries } = this.props;
    updateCustomRegistries([
      ...customRegistries,
      {
        id: `${customRegistries.length + 1}`,
        name: `Custom Registry ${customRegistries.length + 1}`,
        isPending: false,
        isReported: false,
        isReporting: false,
      },
    ]);
  };

  handleCustomRegistryChange = (registry: CustomRegistry) => {
    const { customRegistries, updateCustomRegistries } = this.props;

    const indexOfRegistry = customRegistries.findIndex((x: CustomRegistry) => x.id === registry.id);

    updateCustomRegistries([
      ...customRegistries.slice(0, indexOfRegistry),
      registry,
      ...customRegistries.slice(indexOfRegistry + 1),
    ]);
  };

  handleHiddenRegistryChange = (registry: CustomRegistry, isHidden: boolean) => {
    const { customRegistries, updateHiddenState, updateCustomRegistries, selectedRegistryDisplayNames } = this.props;

    updateHiddenState(registry.name, isHidden, this.isNewReport());

    if (isHidden) {
      updateCustomRegistries(
        !customRegistries.some((c: CustomRegistry) => c.name === registry.name)
          ? [
              ...customRegistries,
              {
                id: `${customRegistries.length + 1}`,
                name: registry.name,
                isPending: registry.isPending,
                isReported: registry.isReported,
                isReporting:
                  selectedRegistryDisplayNames.some((c: string | undefined) => c === registry.name) ||
                  registry.isReporting,
              },
            ]
          : customRegistries
      );
    } else {
      updateCustomRegistries(customRegistries.filter((c: CustomRegistry) => c.name !== registry.name));
    }
  };

  isNewReport = () => {
    const { match } = this.props;
    return match.params.reportId === 'new';
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(RegistriesTable));
