import React from 'react';
import _ from 'lodash';
import { LoadingMessage } from '../../../core';
import ReportType from './ReportType';
import TransplantCentre from './TransplantCentre';
import PrivateNotes from './PrivateNotes';
import ReportRecommendations from './ReportRecommendations';
import { ReceivedDate } from './ReceivedDate';
import { RecentSearchSummary } from './RecentSearchSummary';
import { SelectedDonorSetsTable } from './International/SelectedDonorSetsTable';
import type { ApiSearchRequest, ApiSearchRequestSummary } from '../../../donorMatchSearchRequests/types/api';
import type { SavedResultSet } from '../../../donorMatchSearchRequests/types';

interface DetailsTabProps {
  patientId: string;
  isFetching: boolean;
  selectedDonorSets: SavedResultSet[];
  recentSearch: ApiSearchRequestSummary;
  patientSearchRequests?: ApiSearchRequest[];
  internal: string;
}

export const DetailsTab: React.FC<DetailsTabProps> = ({
  patientId,
  isFetching,
  selectedDonorSets,
  recentSearch,
  patientSearchRequests,
  internal,
}) => (
  <div className="results-table results-table--border" style={{ display: 'flex' }}>
    <div className="col span_6_of_12">
      <ReportRecommendations />
    </div>
    <div className="col span_6_of_12">
      <ReceivedDate />
      <div style={{ marginTop: '20px' }}>
        <ReportType isInternationalReport={false} patientId={patientId} selectedDonorSets={selectedDonorSets} />
      </div>
      <div style={{ marginTop: '20px' }}>
        <TransplantCentre />
      </div>
      <PrivateNotes />
      <div style={{ margin: '20px 0' }}>
        <LoadingMessage isLoading={isFetching}>
          <div style={{ flex: '100%', fontSize: '1.7rem' }}>
            <RecentSearchSummary recentSearch={recentSearch} />
            {!_.isEmpty(selectedDonorSets) && (
              <SelectedDonorSetsTable
                selectedDonorSets={selectedDonorSets}
                patientSearchRequests={patientSearchRequests}
                isInternalSearch={internal}
              />
            )}
          </div>
        </LoadingMessage>
      </div>
    </div>
  </div>
);

DetailsTab.defaultProps = {
  patientSearchRequests: undefined,
};
