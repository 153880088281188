import { LOCATION_CHANGE, RouterState } from 'connected-react-router';
import { ReportStatuses } from '../../../core/constants';
// eslint-disable-next-line import/no-cycle
import { formatRegistries, formatCustomRegistries } from '../../../core/helpers/apiDataConverter';
import type { Registry, CustomRegistry, ReportStatus } from '../../../types';
// eslint-disable-next-line import/no-cycle
import {
  Actions,
  CurrentReportDetailsResetAction,
  CurrentReportGeneratePdfErrorAction,
  CurrentReportGeneratePdfRequestAction,
  CurrentReportGeneratePdfSuccessAction,
  CurrentReportSetReportOnGoingAction,
  CurrentReportStatusUpdateSuccessAction,
  CurrentReportUpdatePrivateNotesAction,
  CurrentReportUpdateReceivedDateAction,
  CurrentReportUpdateReportIdAction,
  CurrentReportUpdateReportTypeAction,
  CurrentReportUpdateSelectedCordsAction,
  CurrentReportUpdateSelectedRegistryDisplayNamesAction,
  CurrentReportUpdateSelectedDonorSetAction,
  CurrentReportUpdateSelectedRecommendationsAction,
  ReportedRegistriesSuccessAction,
  ReportedRegistriesRequestAction,
  SaveNewReportSuccessAction,
  SingleSavedReportSuccessAction,
  TogglePendingCheckboxAction,
  UpdateCustomRegistriesAction,
  UpdateHiddenStateAction,
  UpdateSearchedRegistriesAction,
  CurrentReportDownloadPdfRequestAction,
  CurrentReportDownloadPdfSuccessAction,
  CurrentReportDownloadPdfFinalAttemptErrorAction,
  CurrentReportDownloadPdfFinalAttemptSuccessAction,
} from '../actions';

type LocationChangeAction = { type: typeof LOCATION_CHANGE; payload: RouterState };
type UserActions =
  | CurrentReportDetailsResetAction
  | CurrentReportGeneratePdfErrorAction
  | CurrentReportGeneratePdfRequestAction
  | CurrentReportGeneratePdfSuccessAction
  | CurrentReportSetReportOnGoingAction
  | CurrentReportStatusUpdateSuccessAction
  | CurrentReportUpdatePrivateNotesAction
  | CurrentReportUpdateReceivedDateAction
  | CurrentReportUpdateReportIdAction
  | CurrentReportUpdateReportTypeAction
  | CurrentReportUpdateSelectedCordsAction
  | CurrentReportUpdateSelectedRegistryDisplayNamesAction
  | CurrentReportUpdateSelectedDonorSetAction
  | CurrentReportUpdateSelectedRecommendationsAction
  | LocationChangeAction
  | ReportedRegistriesSuccessAction
  | ReportedRegistriesRequestAction
  | SaveNewReportSuccessAction
  | SingleSavedReportSuccessAction
  | TogglePendingCheckboxAction
  | UpdateCustomRegistriesAction
  | UpdateHiddenStateAction
  | UpdateSearchedRegistriesAction
  | CurrentReportDownloadPdfRequestAction
  | CurrentReportDownloadPdfSuccessAction
  | CurrentReportDownloadPdfFinalAttemptSuccessAction
  | CurrentReportDownloadPdfFinalAttemptErrorAction;

export type ReportDetailsReducerState = {
  additionalNotes: string;
  customRegistries: CustomRegistry[];
  id: string;
  isCreatingPdf: boolean;
  isDownloadingPdf: boolean;
  isInternational?: boolean;
  isRegistryDisplayNameUsed: boolean;
  isSelfAuthorised: boolean | undefined;
  locationChanged: boolean;
  receivedDate?: Date;
  reportOnGoing: boolean;
  reportPdfFileName?: string | undefined;
  reportStatus: ReportStatus;
  reportType?: string;
  searchedRegistries: Partial<Registry>[];
  selectedCordSets: string[];
  selectedRegistryDisplayNames: (string | undefined)[];
  selectedDonorSets: string[];
  selectedRecommendations: string[];
  isAuthorCurrentUser: boolean;
  isFetchingRegistries: boolean;
};

const initialState: ReportDetailsReducerState = {
  additionalNotes: '',
  customRegistries: [],
  id: '',
  isInternational: undefined,
  isCreatingPdf: false,
  isDownloadingPdf: false,
  isRegistryDisplayNameUsed: false,
  isSelfAuthorised: undefined,
  locationChanged: true,
  receivedDate: undefined,
  reportOnGoing: false,
  reportType: '',
  reportStatus: ReportStatuses.Draft,
  searchedRegistries: [],
  selectedCordSets: [],
  selectedRegistryDisplayNames: [],
  selectedDonorSets: [],
  selectedRecommendations: [],
  isAuthorCurrentUser: true,
  isFetchingRegistries: false,
};

const details = (state: ReportDetailsReducerState = initialState, action: UserActions): ReportDetailsReducerState => {
  let indexOfRegistry: number;
  let updatedRegistry: Record<string, unknown>;
  let registries: Partial<Registry>[];
  switch (action.type) {
    case Actions.CURRENT_REPORT_DETAILS_RESET:
      return {
        ...initialState,
      };
    case Actions.CURRENT_REPORT_SET_REPORT_ON_GOING:
      return {
        ...state,
        reportOnGoing: true,
        locationChanged: false,
      };
    case LOCATION_CHANGE:
      if (state.locationChanged) {
        return {
          ...state,
          reportOnGoing: false,
        };
      }
      return {
        ...state,
        locationChanged: true,
      };
    case Actions.CURRENT_REPORT_UPDATE_REPORT_TYPE:
      return {
        ...state,
        reportType: action.payload,
      };
    case Actions.CURRENT_REPORT_UPDATE_SELECTED_DONOR_SETS:
      return {
        ...state,
        selectedDonorSets: action.payload,
      };
    case Actions.CURRENT_REPORT_UPDATE_SELECTED_CORD_SETS:
      return {
        ...state,
        selectedCordSets: action.payload,
      };
    case Actions.CURRENT_REPORT_UPDATE_PRIVATE_NOTES:
      return {
        ...state,
        additionalNotes: action.payload,
      };
    case Actions.CURRENT_REPORT_UPDATE_RECEIVED_DATE:
      return {
        ...state,
        receivedDate: action.payload,
      };
    case Actions.CURRENT_REPORT_UPDATE_SELECTED_RECOMMENDATIONS:
      return {
        ...state,
        selectedRecommendations: action.payload,
      };
    case Actions.CURRENT_REPORT_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        reportStatus: action.payload.params.newStatus,
        isSelfAuthorised: action.payload.params.newIsSelfAuthorised,
      };
    case Actions.CURRENT_REPORT_GENERATE_PDF_REQUEST:
      return {
        ...state,
        reportPdfFileName: undefined,
        isCreatingPdf: true,
      };
    case Actions.CURRENT_REPORT_GENERATE_PDF_SUCCESS:
      return {
        ...state,
        reportPdfFileName: action.payload.data,
        isCreatingPdf: false,
      };
    case Actions.CURRENT_REPORT_UPDATE_REPORT_ID:
      return {
        ...state,
        reportPdfFileName: undefined,
        isCreatingPdf: true,
      };
    case Actions.CURRENT_REPORT_UPDATE_SELECTED_REGISTRY_NAMES:
      return {
        ...state,
        selectedRegistryDisplayNames: action.payload,
      };
    case Actions.SINGLE_SAVED_REPORT_SUCCESS: {
      const reportDetails = action.payload.data.entities.SearchReports[action.payload.data.result];
      return {
        ...state,
        id: reportDetails.Id,
        isInternational: reportDetails.IsInternational,
        isSelfAuthorised: reportDetails.IsSelfAuthorised,
        isRegistryDisplayNameUsed: reportDetails.IsRegistryDisplayNameUsed,
        reportStatus: reportDetails.ReportStatus,
        selectedDonorSets: reportDetails.SelectedDonorSetsIds,
        selectedCordSets: reportDetails.SelectedCordSetsIds,
        additionalNotes: reportDetails.PrivateNotes,
        receivedDate: reportDetails.DateSearchReceived,
        reportPdfFileName: reportDetails.ReportPdfFileName,
        selectedRecommendations: reportDetails.SelectedRecommendations.map((r: string) => r),
        reportType: reportDetails.ReportType,
        isAuthorCurrentUser: reportDetails.IsAuthorCurrentUser,
      };
    }
    case Actions.SAVE_NEW_REPORT_SUCCESS: {
      return {
        ...state,
        id: action.payload.data,
      };
    }
    case Actions.TOGGLE_PENDING_CHECKBOX:
      indexOfRegistry = state.searchedRegistries.findIndex((x) => x.name === action.payload.id);
      updatedRegistry = {
        ...state.searchedRegistries[indexOfRegistry],
        isPending: action.payload.isPending,
      };
      registries = [
        ...state.searchedRegistries.slice(0, indexOfRegistry),
        updatedRegistry,
        ...state.searchedRegistries.slice(indexOfRegistry + 1),
      ];
      return {
        ...state,
        searchedRegistries: registries,
      };
    case Actions.UPDATE_HIDDEN_STATE:
      indexOfRegistry = state.searchedRegistries.findIndex((x) => x.name === action.payload.id);
      updatedRegistry = {
        ...state.searchedRegistries[indexOfRegistry],
        isHidden: action.payload.isHidden,
      };
      registries = [
        ...state.searchedRegistries.slice(0, indexOfRegistry),
        updatedRegistry,
        ...state.searchedRegistries.slice(indexOfRegistry + 1),
      ];
      return {
        ...state,
        searchedRegistries: registries,
      };
    case Actions.UPDATE_CUSTOM_REGISTRIES:
      return {
        ...state,
        customRegistries: action.payload,
      };
    case Actions.CURRENT_REPORT_GENERATE_PDF_ERROR:
      return {
        ...state,
        isCreatingPdf: false,
      };
    case Actions.REPORTED_REGISTRIES_REQUEST:
      return {
        ...state,
        isFetchingRegistries: true,
      };
    case Actions.REPORTED_REGISTRIES_SUCCESS:
      return {
        ...state,
        customRegistries: formatCustomRegistries(action.payload.data.CustomRegistries),
        searchedRegistries: formatRegistries(action.payload.data.SearchedRegistries),
        isFetchingRegistries: false,
      };
    case Actions.UPDATE_SEARCHED_REGISTRIES:
      return {
        ...state,
        searchedRegistries: action.payload,
      };
    case Actions.CURRENT_REPORT_DOWNLOAD_PDF_REQUEST: {
      return {
        ...state,
        isDownloadingPdf: true,
      };
    }
    case Actions.CURRENT_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_ERROR:
    case Actions.CURRENT_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_SUCCESS:
    case Actions.CURRENT_REPORT_DOWNLOAD_PDF_SUCCESS: {
      return {
        ...state,
        isDownloadingPdf: false,
      };
    }

    default:
      return state;
  }
};

export default details;
