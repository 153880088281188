import React from 'react';
import { bindActionCreators, AnyAction, Dispatch as ReduxDispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import * as actions from '../../currentUser/redux/actions';
import CurrentUserSelectors from '../../currentUser/redux/selectors';
import type { ReduxState } from '../../rootReducer';

type StateProps = {
  userLevel?: number;
};

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  getUserLevel: bindActionCreators(actions.getUserLevel, dispatch),
});

const mapStateToProps = (state: ReduxState): StateProps => ({
  userLevel: CurrentUserSelectors.getCurrentUserLevel(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

class UserLevel extends React.Component<Props> {
  static defaultProps = {
    userLevel: undefined,
  };

  componentDidMount() {
    const { getUserLevel, userLevel } = this.props;

    if (userLevel == null) {
      getUserLevel();
    }
  }

  render() {
    const { userLevel } = this.props;

    return (
      <div className="nav-block user-profile">
        <span style={{ paddingRight: '5px' }}>User Level:</span>
        {userLevel != null && <span>{userLevel}</span>}
      </div>
    );
  }
}

export default connector(UserLevel);
