import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';
import type { ReduxState } from '../../../rootReducer';
import type { DonorType } from '../../../core/types';
import { SearchRequestSelectors } from '../../../donorMatchSearchRequests';
import { CurrentReportSelectors } from '../../core/redux/selectors';
import { PatientSelectors } from '../../../patient';
import InternalRequestsList from './InternalRequestsList';
import InternationalRequestsList from './InternationalRequestsList';
import { donorTypes, reportTypes, algorithmTypes } from '../../../core';
import { DetailsTab } from './DetailsTab';
import { InternationalDetailsTab } from './InternationalDetailsTab';

const internalTabItems = [
  { id: 1, name: 'Saved Adult Sets' },
  { id: 2, name: 'Saved Cord Sets' },
  { id: 3, name: 'Details' },
];

const internationalTabItems = [
  { id: 1, name: 'International Donors' },
  { id: 2, name: 'Details' },
];

interface OwnProps {
  patientId: string;
  reportType: string;
  isNewReport: boolean;
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => ({
  isFetchingSearchRequests: SearchRequestSelectors.isFetchingSearchRequests(state),
  isFetchingRegistries: CurrentReportSelectors.isFetchingRegistries(state),
  selectedDonorSets: CurrentReportSelectors.getSelectedDonorSets(state),
  patientSearchRequests: PatientSelectors.getPatientSearchRequest(state, ownProps.patientId),
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & OwnProps;

export const ResultsTabs: React.FC<Props> = ({
  patientId,
  reportType,
  isNewReport,
  isFetchingSearchRequests,
  isFetchingRegistries,
  selectedDonorSets,
  patientSearchRequests,
}) => {
  const getDefaultTab = () => {
    if (isNewReport) {
      return 1;
    }
    return reportType === reportTypes.international ? 2 : 3;
  };

  const [activeTab, setActiveTab] = useState<number>(getDefaultTab());
  const [hasLoadedRegistries, setHasLoadedRegistries] = useState(false);

  const onTabChange = (e: React.MouseEvent, id: number) => {
    e.preventDefault();
    setActiveTab(id);
  };

  const tabItems = reportType === reportTypes.international ? internationalTabItems : internalTabItems;

  const tabs = tabItems.map((item) => (
    <li key={item.id}>
      <a href={`${item.id}`} className={activeTab === item.id ? 'active' : ''} onClick={(e) => onTabChange(e, item.id)}>
        {item.name}
      </a>
    </li>
  ));

  const tenOutOfTenSearches = _.filter(patientSearchRequests, {
    AdultSearchType: 'TenOutOfTen',
  });

  const tenOutOfTenNovaSearches = tenOutOfTenSearches.filter((search) =>
    search.ResultSetSummaries.find(
      (summary) =>
        (summary.AlgorithmUsed === algorithmTypes.novaControl || summary.AlgorithmUsed === algorithmTypes.atlas) &&
        summary.DonorType === donorTypes.adult.value
    )
  );

  const orderedTenOutOfTenSearches = _.orderBy(tenOutOfTenNovaSearches, 'RequestedDate', 'desc');
  const mostRecentTenOutOfTenSearch = orderedTenOutOfTenSearches[0];

  return (
    <div className="group">
      <main role="main" className="col span_12_of_12">
        <ul className="nav-tabs">{tabs}</ul>
        {activeTab === tabItems[0].id && (
          <div className="results-table results-table--border">
            <h2 className="border-bottom-solid">{tabItems[0].name}</h2>
            {reportType === reportTypes.internal ? (
              <InternalRequestsList
                patientId={patientId}
                donorType={donorTypes.adult.value as DonorType}
                reportType={reportType}
              />
            ) : (
              <InternationalRequestsList
                patientId={patientId}
                donorType={donorTypes.adult.value as DonorType}
                reportType={reportType}
              />
            )}
          </div>
        )}
        {activeTab === tabItems[1].id && (
          <div className="results-table results-table--border">
            {reportType === reportTypes.internal ? (
              <div>
                <h2 className="border-bottom-solid">{tabItems[1].name}</h2>
                <InternalRequestsList
                  patientId={patientId}
                  donorType={donorTypes.cord.value as DonorType}
                  reportType={reportType}
                />
              </div>
            ) : (
              <InternationalDetailsTab
                isFetchingSearchRequests={isFetchingSearchRequests}
                isFetchingRegistries={isFetchingRegistries}
                patientId={patientId}
                selectedDonorSets={selectedDonorSets}
                hasLoadedRegistries={hasLoadedRegistries}
                setHasLoadedRegistries={setHasLoadedRegistries}
              />
            )}
          </div>
        )}
        {tabItems[2] && activeTab === tabItems[2].id && (
          <div className="results-table results-table--border">
            <DetailsTab
              recentSearch={mostRecentTenOutOfTenSearch}
              isFetching={isFetchingSearchRequests}
              patientId={patientId}
              selectedDonorSets={selectedDonorSets}
              patientSearchRequests={patientSearchRequests}
              internal={reportTypes.internal}
            />
          </div>
        )}
      </main>
    </div>
  );
};

export default connector(ResultsTabs);
