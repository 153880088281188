import React from 'react';
import ReactTooltip from 'react-tooltip';
import type { ReportStatus } from '../../types';

type Props = {
  handleButtonClick: (status: ReportStatus) => void;
  newStatus: ReportStatus;
  buttonName: string;
  disabled?: boolean;
  tooltip?: string;
};

const ReportPageButton = ({ buttonName, handleButtonClick, newStatus, disabled, tooltip }: Props) => (
  <>
    {disabled && tooltip && <ReactTooltip id={`report-button-tooltip-${newStatus}`}>{tooltip}</ReactTooltip>}
    <div data-tip data-for={`report-button-tooltip-${newStatus}`}>
      <button
        className="btn"
        onClick={() => {
          handleButtonClick(newStatus);
        }}
        type="button"
        disabled={disabled}
      >
        {buttonName}
      </button>
    </div>
  </>
);

ReportPageButton.defaultProps = {
  disabled: false,
  tooltip: '',
};

export default ReportPageButton;
