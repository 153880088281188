import React from 'react';

import type { ReportStatus } from '../../types';
import { getFormattedReportStatus } from '../helpers/getReportStatus';

type Props = {
  status: ReportStatus;
  isSelfAuthorised: boolean | undefined;
};

const ReportStatusDisplay: React.FC<Props> = ({ status, isSelfAuthorised }) => {
  const formattedStatus = getFormattedReportStatus(status, isSelfAuthorised);
  return <span style={{ paddingTop: '10px' }}>{formattedStatus}</span>;
};

export default ReportStatusDisplay;
