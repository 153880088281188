export const UserLevelNumber = {
  LevelOne: 1,
  LevelTwo: 2,
  LevelThree: 3,
  LevelFour: 4,
  LevelFive: 5,
  LevelSix: 6,
};

export type UserLevel = number;
