import type { ReduxState } from '../../rootReducer';
import type { UserLevel } from '../constants';

const isFetchingCurrentUser = (state: ReduxState): boolean =>
  state && state.currentUser && state.currentUser.isFetching;

export const getCurrentUserLevel = (state: ReduxState): UserLevel | undefined =>
  state && state.currentUser ? state.currentUser.level : undefined;

export default {
  isFetchingCurrentUser,
  getCurrentUserLevel,
};
