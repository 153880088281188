import React from 'react';
import { Route, Switch } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import PatientUpdate from './patient/patientCreation/PatientUpdate';
import PatientUpdateGeneticData from './patient/patientCreation/PatientUpdateGeneticData';
import PatientAdjustGeneticData from './patient/patientCreation/PatientAdjustGeneticData';
import ValidationResultsPage from './donorSearchResults/components/ValidationResultsPage/ValidationResultsPage';
import AlgorithmComparisonPage from './donorSearchResults/components/AlgorithmComparisonPage/AlgorithmComparisonPage';
import UserRoleLevelLoader from './currentUser/UserRoleLevelLoader/UserRoleLevelLoader';
import NotAuthorisedPage from './core/components/NotAuthorisedPage/NotAuthorisedPage';
import UserRoleLevelSelectors from './currentUser/redux/selectors';
import { ReportDashboard } from './searchReports';
import ResultsPage from './donorSearchResults/components/ResultsPage';
import FeatureFlagsProvider from './featureFlags/components/FeatureFlagsProvider';
import { Header, HomePage, NotFoundPage } from './core';
import { PatientCreation, PatientPageWrapper } from './patient';
import type { ReduxState } from './rootReducer';
import TransplantCentrePageWrapper from './transplantCentre/components/TransplantCentrePageWrapper';
import DonorDashboard from './donor/DonorDashboard';

import { UserLevelNumber } from './currentUser/constants';

type StateProps = {
  userLevel?: number;
};
type Props = PropsFromRedux & StateProps;

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: ReduxState): StateProps => ({
  userLevel: UserRoleLevelSelectors.getCurrentUserLevel(state),
});

const AppRoutes = ({ userLevel }: Props) => (
  <FeatureFlagsProvider>
    <UserRoleLevelLoader>
      <Header />
      <div style={{ display: 'flex', height: '100%' }} className="col span_12_of_12">
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/donorsearch/:id/results" component={ResultsPage} />
          <Route
            path="/donorsearch/:id/validationResults"
            component={
              userLevel != null && userLevel >= UserLevelNumber.LevelFour ? ValidationResultsPage : NotAuthorisedPage
            }
          />
          <Route
            path="/donorsearch/:id/algorithmComparison"
            component={
              userLevel != null && userLevel >= UserLevelNumber.LevelFour ? AlgorithmComparisonPage : NotAuthorisedPage
            }
          />
          <Route exact path={['/donor/:donorId', '/donor/:donorId/:activeTab']} component={DonorDashboard} />
          <Route exact path="/patient/create" component={PatientCreation} />
          <Route path="/patient/:patientId/adjust/genetic" component={PatientAdjustGeneticData} />
          <Route path="/patient/:patientId/update/genetic" component={PatientUpdateGeneticData} />
          <Route path="/patient/:patientId/update" component={PatientUpdate} />
          <Route path="/patient/:patientId" component={PatientPageWrapper} />
          <Route exact path="/reports/dashboard" component={ReportDashboard} />
          <Route path="/tc-management" component={TransplantCentrePageWrapper} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </UserRoleLevelLoader>
  </FeatureFlagsProvider>
);

const connector = connect(mapStateToProps);

AppRoutes.defaultProps = {
  userLevel: undefined,
};

export default connector(AppRoutes);
