import {
  get,
  ErrorAction as ApiErrorAction,
  RequestAction as ApiRequestAction,
  SuccessAction as ApiSuccessAction,
} from '@an/nova-frontend-rest-client';
import Config from '../../config';
import { type UserLevel } from '../constants';
import type { ReduxState } from '../../rootReducer';

const USER_LEVEL_FETCH: 'searchUserLevel/fetch' = 'searchUserLevel/fetch';
const USER_LEVEL_FETCH_ERROR: 'searchUserLevel/fetch/error' = 'searchUserLevel/fetch/error';
const USER_LEVEL_FETCH_REQUEST: 'searchUserLevel/fetch/request' = 'searchUserLevel/fetch/request';
const USER_LEVEL_FETCH_SUCCESS: 'searchUserLevel/fetch/success' = 'searchUserLevel/fetch/success';

export const Actions = {
  USER_LEVEL_FETCH_REQUEST,
  USER_LEVEL_FETCH_ERROR,
  USER_LEVEL_FETCH_SUCCESS,
};

type UserLevelFetchErrorAction = ApiErrorAction<typeof USER_LEVEL_FETCH_ERROR, Record<string, unknown>>;

type UserLevelFetchRequestAction = ApiRequestAction<typeof USER_LEVEL_FETCH_REQUEST, Record<string, unknown>>;

type UserLevelFetchSuccessAction = ApiSuccessAction<
  typeof USER_LEVEL_FETCH_SUCCESS,
  Record<string, unknown>,
  UserLevel
>;

export type CurrentUserAction = UserLevelFetchErrorAction | UserLevelFetchRequestAction | UserLevelFetchSuccessAction;

export const getUserLevel = () =>
  get<ReduxState, UserLevel>(`${Config().apiBaseUrl}current-user/role-level`, USER_LEVEL_FETCH);
