import React, { PureComponent } from 'react';
import RegistryCheckbox from './RegistryCheckbox';
import type { CustomRegistry } from '../../../types';
import isWhiteSpaceOrEmpty from '../../../../core/helpers/isWhiteSpaceOrEmpty';
import { Button } from '../../../../core';

type Props = {
  registry: CustomRegistry;
  readOnly: boolean;
  onRegistryChange: (registry: CustomRegistry) => void;
  onDeleteCustomRegistry: (registryId: string) => void;
};
type State = {
  registryDisplayName: string;
};

class CustomRegistryRow extends PureComponent<Props, State> {
  state = {
    // eslint-disable-next-line react/destructuring-assignment
    registryDisplayName: this.props.registry.name,
  };

  render() {
    const { registry, readOnly, onRegistryChange, onDeleteCustomRegistry } = this.props;

    const { registryDisplayName } = this.state;

    return (
      <tr
        key={registry.name}
        className={!registry.isReporting && !registry.isPending && !registry.isReported ? 'disabled' : ''}
      >
        <td>
          <input
            className={isWhiteSpaceOrEmpty(registry.name) ? 'error' : undefined}
            disabled={readOnly}
            onBlur={this.saveRegistryDisplayNameChange}
            onChange={this.handleRegistryDisplayNameChange}
            onKeyPress={this.handleKeyPress}
            placeholder="Add new registry"
            type="text"
            value={registryDisplayName}
          />
        </td>
        <td>
          <RegistryCheckbox
            checked={registry.isReporting}
            disabled={readOnly}
            name="isReporting"
            id={registry.id}
            onChange={(value) => onRegistryChange({ ...registry, isReporting: value || false })}
          />
        </td>
        <td>
          <RegistryCheckbox
            checked={registry.isPending || false}
            disabled={readOnly}
            name="isPending"
            id={registry.id}
            onChange={(value) => onRegistryChange({ ...registry, isPending: value || false })}
          />
        </td>
        <td>
          <RegistryCheckbox
            checked={registry.isReported}
            disabled={readOnly}
            name="isReported"
            id={registry.id}
            onChange={(value) => onRegistryChange({ ...registry, isReported: value || false })}
          />
        </td>
        {!readOnly && (
          <td>
            <Button
              buttonClass="btn btn--table btn--secondary"
              text="Delete"
              onClick={() => onDeleteCustomRegistry(registry.id)}
            />
          </td>
        )}
      </tr>
    );
  }

  handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.saveRegistryDisplayNameChange();
    }
  };

  handleRegistryDisplayNameChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      registryDisplayName: target.value,
    });
  };

  saveRegistryDisplayNameChange = () => {
    const { onRegistryChange, registry } = this.props;
    const { registryDisplayName } = this.state;

    onRegistryChange({
      ...registry,
      name: registryDisplayName,
    });
  };
}

export default CustomRegistryRow;
