// eslint-disable-next-line import/no-cycle
import { Actions, CurrentUserAction } from './actions';

import type { UserLevel } from '../constants';

export type CurrentUserState = {
  readonly isAuthorised?: boolean;
  readonly isFetching: boolean;
  readonly hasErrored: boolean;
  readonly message?: string;
  readonly level?: UserLevel;
};

const initialState: CurrentUserState = {
  hasErrored: false,
  isAuthorised: undefined,
  isFetching: false,
  level: undefined,
};

export default (state: CurrentUserState = initialState, action: CurrentUserAction): CurrentUserState => {
  switch (action.type) {
    case Actions.USER_LEVEL_FETCH_REQUEST:
      return {
        ...state,
        hasErrored: false,
        isFetching: true,
      };
    case Actions.USER_LEVEL_FETCH_ERROR:
      return {
        ...state,
        hasErrored: true,
        isFetching: false,
        isAuthorised: action.payload.response.status === 401,
        message: `Error while fetching Users - ${action.payload.response.status}`,
      };
    case Actions.USER_LEVEL_FETCH_SUCCESS:
      return {
        ...state,
        hasErrored: false,
        isFetching: false,
        level: action.payload.data,
      };
    default:
      return state;
  }
};
