import React from 'react';
import Select, { SingleValue } from 'react-select';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { AutoSizer, Column, Table } from 'react-virtualized';
import moment, { Moment } from 'moment';
import cn from 'classnames';

import * as actions from '../../../../../externalInvestigations/redux/actions';
import formatRemark from '../../../../../core/helpers/remarkFormatter';
import Error from '../../../../../core/components/Error/Error';
import selectors from '../../../../../externalInvestigations/redux/selectors';
import formatGrid from '../../../../../core/helpers/gridFormatter';
import { CancellationReasons } from '../../../../../externalInvestigations/types';
import { isOddNumber } from '../../../../../core/helpers/arrayHelper';
import type { ReduxState } from '../../../../../rootReducer';
import { SingleLineTextArea } from '../../../../../core/components/SingleLineTextArea';
import type {
  ExternalInvestigation,
  CancellationReason,
  ExternalInvestigationsCancellationRequest,
} from '../../../../../externalInvestigations/types';
import {
  externalInvestigationTypes,
  getExternalInvestigationTypeNameInTable,
} from '../../../../../core/constants/externalInvestigationTypes';

type StateProps = {
  cancellingExternalInvestigationsInProgress: boolean;
  cancelExternalInvestigationsError: string | null | undefined;
  cancelExternalInvestigationsHasErrored: boolean;
};
type OwnProps = {
  patientId: string;
  externalInvestigations: ExternalInvestigation[];
  selectedExtendedTypingInvestigationIds: number[];
  selectedIdmInvestigationIds: number[];
  selectedVerificationTypingInvestigationIds: number[];
  onCancel: (
    event: React.SyntheticEvent<HTMLButtonElement> & {
      currentTarget: HTMLButtonElement;
    }
  ) => void;
};
type Props = OwnProps & StateProps & PropsFromRedux;

type State = {
  cancellationReason: CancellationReason | null | undefined;
  cancellationRemark: string | null | undefined;
  cancellationAttempted: boolean;
};

type SelectedInvestigationDetails = {
  investigationType: string;
  grid: string;
  registryName: string;
  requestedTimeMoment: Moment;
};

type CancelExternalInvestigationsPopUpOption = {
  value: CancellationReason;
  label: string;
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  cancellingExternalInvestigationsInProgress: selectors.getIsCancellingExternalInvestigations(state),
  cancelExternalInvestigationsError: selectors.getCancelExternalInvestigationsError(state),
  cancelExternalInvestigationsHasErrored: selectors.hasCancelExternalInvestigationsErrored(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  cancelExternalInvestigations: bindActionCreators(actions.cancelExternalInvestigations, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const cancellationReasonToSelectOption = (
  cancellationReasonKey: CancellationReason
): CancelExternalInvestigationsPopUpOption => ({
  value: cancellationReasonKey,
  label: CancellationReasons[cancellationReasonKey],
});

export class CancelExternalInvestigationsPopUp extends React.Component<Props, State> {
  state = {
    cancellationReason: undefined,
    cancellationRemark: undefined,
    cancellationAttempted: false,
  };

  render() {
    const { onCancel, cancelExternalInvestigationsError, cancellingExternalInvestigationsInProgress } = this.props;
    const { cancellationReason } = this.state;

    return (
      <form onSubmit={this.submitExternalInvestigationsCancellation}>
        <h1 id="cancelExternalInvestigationHeader">Cancel these test requests?</h1>
        {this.renderCancellationDetailsForm()}
        {this.renderSelectedExternalInvestigations()}
        <div className="btn-actions">
          <button
            id="cancelExternalInvestigationSubmitButton"
            className="btn btn--inline btn--search-request"
            disabled={cancellationReason === undefined || cancellingExternalInvestigationsInProgress}
            type="submit"
          >
            Cancel Requests
          </button>
          <button
            id="cancelExternalInvestigationCancelButton"
            className="btn btn--secondary btn--inline"
            onClick={onCancel}
            type="button"
          >
            Close
          </button>
        </div>
        {this.shouldShowCancellationError() && (
          <Error
            id="externalInvestigationCancellationError"
            error={cancelExternalInvestigationsError ?? 'An error has occurred while cancelling test requests'}
          />
        )}
      </form>
    );
  }

  renderCancellationDetailsForm = () => {
    const { cancellationReason, cancellationRemark } = this.state;

    const cancellationReasonOptions = Object.keys(CancellationReasons).map((cancellationReasonOption) =>
      cancellationReasonToSelectOption(cancellationReasonOption as CancellationReason)
    );
    return (
      <>
        <p id="cancellationReasonLabel">Select cancellation reason</p>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          id="cancellationReasons"
          options={cancellationReasonOptions}
          value={cancellationReason ? cancellationReasonToSelectOption(cancellationReason) : undefined}
          onChange={this.handleCancellationReasonChange}
          aria-labelledby="cancellationReasonLabel"
        />
        <div>
          <SingleLineTextArea
            id="cancellationRemark"
            rows={1}
            maxLength={120}
            placeholder="Remarks"
            value={cancellationRemark}
            onChange={this.handleCancellationRemarkChange}
            style={{ marginTop: '20px' }}
          />
        </div>
      </>
    );
  };

  renderSelectedExternalInvestigations = () => {
    const {
      selectedExtendedTypingInvestigationIds,
      selectedIdmInvestigationIds,
      selectedVerificationTypingInvestigationIds,
    } = this.props;

    const selectedExtendedTypingInvestigationDetails = this.mapIdsToInvestigationDetails(
      selectedExtendedTypingInvestigationIds,
      externalInvestigationTypes.extendedTyping.value
    );

    const selectedIdmInvestigationDetails = this.mapIdsToInvestigationDetails(
      selectedIdmInvestigationIds,
      externalInvestigationTypes.idm.value
    );

    const selectedVerificationTypingInvestigationDetails = this.mapIdsToInvestigationDetails(
      selectedVerificationTypingInvestigationIds,
      externalInvestigationTypes.vt.value
    );

    const allSelectedInvestigationDetails = selectedExtendedTypingInvestigationDetails
      .concat(selectedIdmInvestigationDetails)
      .concat(selectedVerificationTypingInvestigationDetails)
      // Flow throws an error here but this is the recommended way to sort Moment.js dates:
      // https://github.com/moment/moment/issues/1493
      // $FlowExpectedError - suppressing the error.
      .sort((a, b) => (b.requestedTimeMoment as unknown as number) - (a.requestedTimeMoment as unknown as number));

    return (
      allSelectedInvestigationDetails.length > 0 && (
        <>
          <p id="externalInvestigationCancellationLabel">The following test requests will be cancelled:</p>
          {this.renderSelectedExternalInvestigationTable(allSelectedInvestigationDetails)}
        </>
      )
    );
  };

  mapIdsToInvestigationDetails = (
    selectedInvestigationIds: number[],
    investigationTypeValue: string
  ): SelectedInvestigationDetails[] => {
    const { externalInvestigations } = this.props;

    if (externalInvestigations === undefined) {
      return [];
    }

    const investigations = externalInvestigations
      .filter(
        (investigation) =>
          selectedInvestigationIds.includes(investigation.id) && investigation.type === investigationTypeValue
      )
      .map((investigation) => ({
        investigationType: getExternalInvestigationTypeNameInTable(investigationTypeValue),
        grid: investigation.grid,
        registryName: investigation.registryName,
        requestedTimeMoment: moment(investigation.requestedTime),
      }));

    return investigations;
  };

  renderSelectedExternalInvestigationTable = (selectedInvestigationDetails: SelectedInvestigationDetails[]) => {
    const availableTableHeight = 350;
    const maxHeight = Math.min(availableTableHeight, (selectedInvestigationDetails.length + 1) * 50);

    return (
      <div id="tableWrapper" style={{ display: 'flex' }}>
        <div style={{ flex: '1 1 auto', height: `${maxHeight}px` }}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                id="openSearches"
                className="open-searches-table"
                width={width}
                height={height}
                headerHeight={50}
                rowHeight={50}
                rowCount={selectedInvestigationDetails.length}
                rowGetter={({ index }) => selectedInvestigationDetails[index]}
                rowClassName={({ index }) => this.getRowClass(index)}
              >
                <Column label="Type" dataKey="investigationType" width={width / 4} />

                <Column
                  label="Grid"
                  dataKey="grid"
                  width={width / 4}
                  cellRenderer={({ cellData }) => formatGrid(cellData)}
                />

                <Column label="Emdis Registry" dataKey="registryDisplayName" width={width / 2} />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  };

  handleCancellationRemarkChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ cancellationRemark: event.currentTarget.value });
  };

  handleCancellationReasonChange = (newValue: SingleValue<CancelExternalInvestigationsPopUpOption>) => {
    const value = newValue?.value;
    this.setState({ cancellationReason: value });
  };

  submitExternalInvestigationsCancellation = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const {
      patientId,
      selectedExtendedTypingInvestigationIds,
      selectedIdmInvestigationIds,
      selectedVerificationTypingInvestigationIds,
      cancelExternalInvestigations,
    } = this.props;
    const { cancellationReason, cancellationRemark } = this.state;

    if (!cancellationReason) {
      return;
    }

    this.setState({ cancellationAttempted: true });

    const formattedRemark = formatRemark(cancellationRemark);

    const cancellationRequest: ExternalInvestigationsCancellationRequest = {
      patientId,
      extendedTypingInvestigationIds: selectedExtendedTypingInvestigationIds,
      idmInvestigationIds: selectedIdmInvestigationIds,
      verificationTypingInvestigationIds: selectedVerificationTypingInvestigationIds,
      cancellationReason,
      cancellationRemark: formattedRemark,
    };

    cancelExternalInvestigations(patientId, cancellationRequest);
  };

  getRowClass = (rowIndex: number) => {
    const periodicityClass = isOddNumber(rowIndex) ? 'odd' : 'even';

    return cn('cell', periodicityClass);
  };

  shouldShowCancellationError = () =>
    !this.props.cancellingExternalInvestigationsInProgress &&
    this.props.cancelExternalInvestigationsHasErrored &&
    this.state.cancellationAttempted;
}

export default connector(CancelExternalInvestigationsPopUp);
