import React from 'react';

import '../../internationalDonorsTableStyling.scss';

import RegistryCheckbox from './RegistryCheckbox';

import type { Registry } from '../../../types';

type Props = {
  registry: Registry;
  readOnly: boolean;
  isReportingOverride?: () => boolean;
  onHiddenChange: (isHidden: boolean) => void;
  onPendingChange: (isPending: boolean) => void;
};

const RegistryRow = (props: Props) => {
  const { registry, isReportingOverride, readOnly, onHiddenChange, onPendingChange } = props;

  const isReporting = isReportingOverride ? isReportingOverride() : registry.isReporting;

  return (
    <tr key={registry.name} className={registry.isHidden ? 'disabled' : ''}>
      <td>{registry.name}</td>
      <td>
        <RegistryCheckbox checked={isReporting} name="isReporting" id={registry.name} disabled />
      </td>
      <td>
        <RegistryCheckbox
          checked={registry.isPending || false}
          disabled={readOnly}
          name="isPending"
          id={registry.name}
          onChange={onPendingChange}
        />
      </td>
      <td>
        <RegistryCheckbox checked={registry.isReported} name="isReported" id={registry.name} disabled />
      </td>
      {!readOnly && (
        <td>
          <RegistryCheckbox
            checked={registry.isHidden || false}
            disabled={readOnly}
            name="isHidden"
            id={registry.name}
            onChange={onHiddenChange}
          />
        </td>
      )}
    </tr>
  );
};

RegistryRow.defaultProps = {
  isReportingOverride: undefined,
};

export default RegistryRow;
