import React from 'react';
import _ from 'lodash';
import { LoadingMessage } from '../../../core';
import ReportType from './ReportType';
import TransplantCentre from './TransplantCentre';
import PrivateNotes from './PrivateNotes';
import { ReceivedDate } from './ReceivedDate';
import RegistriesTable from './International/RegistriesTable';
import { SelectedDonorSetsTable } from './International/SelectedDonorSetsTable';
import type { SavedResultSet } from '../../../donorMatchSearchRequests/types';

interface InternationalDetailsTabProps {
  patientId: string;
  isFetchingSearchRequests: boolean;
  isFetchingRegistries: boolean;
  selectedDonorSets: SavedResultSet[];
  hasLoadedRegistries: boolean;
  setHasLoadedRegistries: (hasLoadedRegistries: boolean) => void;
}

export const InternationalDetailsTab: React.FC<InternationalDetailsTabProps> = ({
  isFetchingSearchRequests,
  isFetchingRegistries,
  patientId,
  selectedDonorSets,
  hasLoadedRegistries,
  setHasLoadedRegistries,
}) => (
  <div>
    <h2 className="border-bottom-solid">International Report</h2>
    <div style={{ display: 'flex', margin: '40px 0' }}>
      <div style={{ flex: '50%', marginRight: '40px' }}>
        {!isFetchingRegistries ? (
          <RegistriesTable hasLoadedRegistries={hasLoadedRegistries} setHasLoadedRegistries={setHasLoadedRegistries} />
        ) : (
          <LoadingMessage />
        )}
        {!isFetchingSearchRequests && !_.isEmpty(selectedDonorSets) && (
          <SelectedDonorSetsTable selectedDonorSets={selectedDonorSets} />
        )}
      </div>
      <div style={{ flex: '50%' }}>
        <ReceivedDate />
        <div style={{ marginTop: '20px' }}>
          <ReportType isInternationalReport patientId={patientId} />
        </div>
        <TransplantCentre />
        <PrivateNotes />
      </div>
    </div>
  </div>
);
