import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from '../Button';

const NotAuthorisedPage = () => {
  const { instance } = useMsal();

  const onSignOut = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    instance.logoutRedirect();
  };

  return (
    <div className="col span_12_of_12">
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <div>
          <h1>Access Denied</h1>
          <h3>You are not eligible to access the Search portal.</h3>
          <h3>Please use “Report An Issue“ button to request access.</h3>
          <Button text="Sign Out" onClick={onSignOut} />
        </div>
      </div>
    </div>
  );
};

export default NotAuthorisedPage;
