import { ReportStatuses } from '../../core/constants';

export const getFormattedReportStatus = (status: string, isSelfAuthorised: boolean | undefined): string => {
  if (status === ReportStatuses.Authorised && isSelfAuthorised === true) {
    return 'Finalised';
  }

  if (status === ReportStatuses.AwaitingAuthorisation) {
    return 'Awaiting Authorisation';
  }

  return status;
};
