import type { ApiSearchedRegistryRetrieveModel, ApiCustomRegistry } from '../../types/api';
import type { Registry, CustomRegistry } from '../../types';

export const formatRegistries = (registries: ApiSearchedRegistryRetrieveModel[]): Registry[] =>
  registries.map((registry) => ({
    name: registry.Name,
    isHidden: registry.IsHidden,
    isPending: registry.IsPending,
    isReported: registry.IsReported,
    isReporting: registry.IsReporting,
  }));

export const formatCustomRegistries = (registries: ApiCustomRegistry[]): CustomRegistry[] =>
  registries.map((registry, index) => ({
    id: index.toString(),
    name: registry.Name,
    isPending: registry.IsPending,
    isReported: registry.IsReported,
    isReporting: registry.IsReporting,
  }));
