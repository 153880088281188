import { ReportStatuses } from '../../core/constants';
// eslint-disable-next-line import/no-cycle
import SavedReportsSelectors from '../../savedReports/redux/selectors';
import { donorTypes, matchGradeConstants, reportTypes } from '../../../core';
import { SearchRequestSelectors } from '../../../donorMatchSearchRequests/index';

import type { ReduxState } from '../../../rootReducer';
import type { Registry, CustomRegistry, DonorMatchCount, MatchTotals, SaveReportModel } from '../../types';
import type { ApiCustomRegistry, ApiRecommendation, ApiSearchedRegistrySaveModel } from '../../types/api';
import type { SavedResultSet, SelectedResult } from '../../../donorMatchSearchRequests/types';
import type { ErrorMessages } from '../../../core/types/index';

const getCurrentReport = (state: ReduxState) => state.searchReport.currentReport;

const isReportOnGoing = (state: ReduxState): boolean => getCurrentReport(state).details.reportOnGoing;

const removeDuplicateSelections = (selectedItems: SelectedResult[]): SelectedResult[] =>
  selectedItems.reduce((previous: SelectedResult[], current) => {
    if (!previous.some((element) => element.id === current.id)) {
      previous.push(current);
    }
    return previous;
  }, []);

const getSelectedResultSetIds = (state: ReduxState, donorTypeAdultValue: string) => {
  const reportDetails = getCurrentReport(state).details;
  return donorTypeAdultValue === donorTypes.adult.value
    ? reportDetails.selectedDonorSets
    : reportDetails.selectedCordSets;
};

const getSelectedDonorSets = (state: ReduxState): SavedResultSet[] => {
  const selectedDonorSetIds = getSelectedResultSetIds(state, donorTypes.adult.value);
  return selectedDonorSetIds.map((id) => state.latestSearchRequests.savedDonorSets[id]);
};

const flatten = (arr: any) => arr.reduce((previous: any, current: any) => [...previous, ...current], []);

const mergeResultSets = (donorSets: SavedResultSet[]): SelectedResult[] =>
  flatten(donorSets.map((donorSet) => donorSet.selectedResults));

export const getUniqueResultsFromResultSets = (resultSets: SavedResultSet[]): SelectedResult[] => {
  const results = resultSets ? mergeResultSets(resultSets) : [];
  return removeDuplicateSelections(results);
};

const getSelectedDonors = (state: ReduxState): SelectedResult[] => {
  const selectedDonorSets = getSelectedDonorSets(state);
  return getUniqueResultsFromResultSets(selectedDonorSets);
};

const incrementCount = (matchTypeTotals: DonorMatchCount, grade: keyof DonorMatchCount): DonorMatchCount => ({
  ...matchTypeTotals,
  [grade]: matchTypeTotals[grade] + 1,
});

const countMatchesByGrade = (matchTypeTotals: DonorMatchCount, selectedDonor: SelectedResult): DonorMatchCount => {
  switch (selectedDonor.matchGrade) {
    case matchGradeConstants.matchGrade.Exact:
      return incrementCount(matchTypeTotals, 'exact');
    case matchGradeConstants.matchGrade.Potential:
      return incrementCount(matchTypeTotals, 'potential');
    case matchGradeConstants.matchGrade.MinorMismatch:
      return incrementCount(matchTypeTotals, 'minorMismatch');
    default:
      return incrementCount(matchTypeTotals, 'mismatch');
  }
};

const emptyCount: DonorMatchCount = {
  exact: 0,
  potential: 0,
  minorMismatch: 0,
  mismatch: 0,
};

const countMatchesByType = (selectedDonors: SelectedResult[], matchType: string): DonorMatchCount =>
  selectedDonors.filter((donor) => donor.matchType === matchType).reduce(countMatchesByGrade, emptyCount);

const getMatchTotals = (selectedDonors: SelectedResult[]): MatchTotals => ({
  ABDR: countMatchesByType(selectedDonors, 'ABDR'),
  AB: countMatchesByType(selectedDonors, 'AB'),
});

const getSearchSummary = (state: ReduxState): MatchTotals => {
  const selectedDonors = getSelectedDonors(state);
  return getMatchTotals(selectedDonors);
};

const recommendationsList = (state: ReduxState) => getCurrentReport(state).recommendations;

const getRecommendationsList = (state: ReduxState): ApiRecommendation[] =>
  // $FlowExpectedError - Flow struggles with Object.values
  Object.values(recommendationsList(state).list);

const isRecommendationsFetching = (state: ReduxState): boolean => recommendationsList(state).isFetching;

const hasRecommendationListErrored = (state: ReduxState): boolean => recommendationsList(state).hasErrored;

const getRecommendationListErrorMessage = (state: ReduxState): ErrorMessages => {
  const error = recommendationsList(state).message;
  return error ? [error] : [];
};

const getSelectedRecommendations = (state: ReduxState): string[] =>
  getCurrentReport(state).details.selectedRecommendations;

const getPrivateNotes = (state: ReduxState): string => getCurrentReport(state).details.additionalNotes;

const getReceivedDate = (state: ReduxState): Date | undefined => getCurrentReport(state).details.receivedDate;

const getTransplantCentreAddress = (state: ReduxState): string[] => getCurrentReport(state).transplantCentre.address;

const isTransplantCentreFetching = (state: ReduxState): boolean => getCurrentReport(state).transplantCentre.isFetching;

const hasfetchTransplantCentreHasErrored = (state: ReduxState): boolean =>
  getCurrentReport(state).transplantCentre.hasErrored;

const getTransplantCentreErrorMessage = (state: ReduxState): ErrorMessages => {
  const error = getCurrentReport(state).transplantCentre.message;
  return error ? [error] : [];
};

const getCustomRegistries = (state: ReduxState) => getCurrentReport(state).details.customRegistries;

const getSearchedRegistries = (state: ReduxState) => getCurrentReport(state).details.searchedRegistries;

const getSelectedRegistryNames = (state: ReduxState) => getCurrentReport(state).details.selectedRegistryDisplayNames;

const getReportStatus = (state: ReduxState): string => getCurrentReport(state).details.reportStatus;

const getIsAuthorCurrentUser = (state: ReduxState): boolean => getCurrentReport(state).details.isAuthorCurrentUser;

const isSelfAuthorised = (state: ReduxState): boolean | undefined => getCurrentReport(state).details.isSelfAuthorised;

const getIsRegistryDisplayNameUsed = (state: ReduxState): boolean =>
  getCurrentReport(state).details.isRegistryDisplayNameUsed;

const isReportReadOnly = (state: ReduxState): boolean => getReportStatus(state) !== ReportStatuses.Draft;

const isReportInternational = (state: ReduxState): boolean | undefined =>
  getCurrentReport(state).details.isInternational;

const getReportType = (state: ReduxState): string | undefined => getCurrentReport(state).details.reportType;

const getCustomRegistriesForApi = (state: ReduxState): ApiCustomRegistry[] => {
  const allCustomRegistries = getCustomRegistries(state);
  return allCustomRegistries.map((registry: CustomRegistry) => ({
    Name: registry.name,
    IsPending: registry.isPending,
    IsReported: registry.isReported,
    IsReporting: registry.isReporting,
  }));
};

const getSearchedRegistriesForApi = (state: ReduxState): ApiSearchedRegistrySaveModel[] => {
  const allSearchedRegistries = getSearchedRegistries(state);
  return allSearchedRegistries.map((registry: Partial<Registry>) => ({
    Name: registry.name as string,
    IsHidden: registry.isHidden as boolean,
    IsPending: registry.isPending as boolean,
  }));
};

const getCurrentReportObject = (state: ReduxState, reportType: string) => ({
  TransplantCentre: getTransplantCentreAddress(state),
  DateSearchReceived: getReceivedDate(state),
  SearchSummary: getSearchSummary(state),
  SelectedDonorSetsIds: getSelectedResultSetIds(state, donorTypes.adult.value),
  SelectedCordSetsIds: getSelectedResultSetIds(state, donorTypes.cord.value),
  Recommendations: getRecommendationsList(state),
  SelectedRecommendations: getSelectedRecommendations(state),
  PrivateNotes: getPrivateNotes(state),
  ReportType: getReportType(state),
  SearchedRegistries: getSearchedRegistriesForApi(state),
  CustomRegistries: getCustomRegistriesForApi(state),
  IsInternational: reportType === reportTypes.international,
});

const makeNewReportObject = (patientId: string, state: ReduxState, reportType: string): SaveReportModel => ({
  PatientId: patientId,
  ...getCurrentReportObject(state, reportType),
});

const hasFetchReportGenerationDataErrored = (state: ReduxState): boolean =>
  hasfetchTransplantCentreHasErrored(state) ||
  hasRecommendationListErrored(state) ||
  SearchRequestSelectors.hasUserRequestsFetchErrored(state) ||
  SavedReportsSelectors.hasFetchReportsErrored(state);

const getAllErrorMessages = (state: ReduxState): ErrorMessages => [
  ...getTransplantCentreErrorMessage(state),
  ...getRecommendationListErrorMessage(state),
  ...SearchRequestSelectors.getRequestsErrorMessage(state),
  ...SavedReportsSelectors.getReportErrorMessage(state),
];

const getReportPdfFileName = (state: ReduxState): string | undefined =>
  getCurrentReport(state).details.reportPdfFileName;

const getCurrentReportId = (state: ReduxState): string => getCurrentReport(state).details.id;

const isPdfCreated = (state: ReduxState): boolean => getCurrentReport(state).details.reportPdfFileName !== undefined;

const isCreatingPdf = (state: ReduxState): boolean => getCurrentReport(state).details.isCreatingPdf;

const isDownloadingPdf = (state: ReduxState): boolean => getCurrentReport(state).details.isDownloadingPdf;

const isFetchingRegistries = (state: ReduxState): boolean => getCurrentReport(state).details.isFetchingRegistries;

export default {
  makeNewReportObject,
  hasfetchTransplantCentreHasErrored,
  getTransplantCentreAddress,
  getPrivateNotes,
  getSelectedRecommendations,
  getRecommendationsList,
  getSelectedResultSetIds,
  getCurrentReportObject,
  getReportStatus,
  isSelfAuthorised,
  isReportReadOnly,
  isReportInternational,
  getTransplantCentreErrorMessage,
  hasRecommendationListErrored,
  getRecommendationListErrorMessage,
  getSelectedDonorSets,
  getCustomRegistries,
  getSearchedRegistries,
  getSelectedRegistryDisplayNames: getSelectedRegistryNames,
  hasFetchReportGenerationDataErrored,
  getAllErrorMessages,
  getReportPdfFileName,
  isTransplantCentreFetching,
  isRecommendationsFetching,
  getCurrentReportId,
  isReportOnGoing,
  isPdfCreated,
  isCreatingPdf,
  isDownloadingPdf,
  getReportType,
  getReceivedDate,
  getIsRegistryDisplayNameUsed,
  getIsAuthorCurrentUser,
  isFetchingRegistries,
};
