import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import { FeatureFlag } from '../../../featureFlags';
import * as actions from '../redux/actions';
import DetailedPatientDisplay from '../../core/components/DetailedPatientDisplay/DetailedPatientDisplay';
import Selectors from '../redux/selectors';
import ResultsTabs from './ResultsTables/ResultsTabs';
import RepeatSearchButton from './RepeatSearchButton';
import { SendPatientToWmdaButton } from './SendPatientToWmdaButton';
import type { RouterMatch } from '../../../core/types';
import type { ReduxState } from '../../../rootReducer';
import ClosePatientPopUp from './ClosePatientPopUp';
import { PopUpWrapper } from '../../../core';
import { styles } from '../style';
import { OpenPatientPopUp } from './OpenPatientPopUp';

const patientDashboardStyles = {
  closePatientPopUpStyle: {
    ...styles.patientDashboardPopUp,
    width: '700px',
    minHeight: '550px',
  },
};

type StateProps = {
  date?: Moment;
  isPatientClosed: boolean | null | undefined;
};
type OwnProps = {
  match: RouterMatch;
};
type Props = PropsFromRedux & StateProps & OwnProps;
type State = {
  isClosePatientPopUpShown: boolean;
};

const mapStateToProps = (state: ReduxState, props: OwnProps): StateProps => {
  const { patientId } = props.match.params;
  return {
    date: Selectors.getRepeatSearchDate(state, patientId),
    isPatientClosed: Selectors.getIsPatientClosed(state, patientId),
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  fetchRepeatSearchDate: bindActionCreators(actions.fetchRepeatSearchDate, dispatch),
  getPatientDashboardData: bindActionCreators(actions.getPatientDashboardData, dispatch),
  updateRepeatSearchDate: bindActionCreators(actions.updateRepeatSearchDate, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class PatientDashboard extends PureComponent<Props, State> {
  static defaultProps = {
    date: undefined,
  };

  state = {
    isClosePatientPopUpShown: true,
  };

  componentDidMount() {
    const { fetchRepeatSearchDate, getPatientDashboardData, match } = this.props;
    const { patientId } = match.params;

    if (patientId) {
      Promise.all([fetchRepeatSearchDate(patientId), getPatientDashboardData(patientId)]);
    }
  }

  render() {
    const { date, match, isPatientClosed } = this.props;
    const { patientId } = match.params;
    const { isClosePatientPopUpShown } = this.state;

    return patientId ? (
      <div style={{ width: '100%' }}>
        <div className="col span_12_of_12">
          <DetailedPatientDisplay patientId={patientId} shouldShowExtraInfoByDefault />
        </div>
        <div
          className="col span_12_of_12"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Link to={`/patient/${patientId}/update`} className="btn btn--inline">
              Update Patient Details
            </Link>
            <Link to={`/patient/${patientId}/update/genetic`} className="btn btn--inline">
              Update Patient Genetics
            </Link>
            <FeatureFlag
              flag="showAdjustHLAButton"
              render={() => (
                <Link to={`/patient/${patientId}/adjust/genetic`} className="btn">
                  Adjust HLA
                </Link>
              )}
            />
            <SendPatientToWmdaButton patientId={patientId} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <FeatureFlag
              flag="showClosePatientButton"
              render={() => (
                <>
                  {isPatientClosed && (
                    <PopUpWrapper
                      placement="right"
                      name="Open Patient"
                      buttonClassName="btn btn--inline btn--search-request"
                      popUpStyle={patientDashboardStyles.closePatientPopUpStyle}
                      shouldDarkenBackground
                      popoverContainerClassName="react-tiny-popover-container--center"
                    >
                      <OpenPatientPopUp patientId={patientId} />
                    </PopUpWrapper>
                  )}
                  {isPatientClosed === false && (
                    <PopUpWrapper
                      placement="right"
                      name="Close Patient"
                      isPopUpShown={isClosePatientPopUpShown}
                      onClick={this.handleClosePatientPopUpClick}
                      buttonClassName="btn btn--inline btn--search-request"
                      popUpStyle={patientDashboardStyles.closePatientPopUpStyle}
                      shouldDarkenBackground
                      popoverContainerClassName="react-tiny-popover-container--center"
                    >
                      <ClosePatientPopUp patientId={patientId} onCancel={this.handleCancelPatientClosure} />
                    </PopUpWrapper>
                  )}
                </>
              )}
            />
            <RepeatSearchButton onClick={this.handleRepeatSearchButtonClick} date={date} />
          </div>
        </div>
        <div className="col span_12_of_12">
          <ResultsTabs match={match} patientId={patientId} isPatientClosed={isPatientClosed} />
        </div>
      </div>
    ) : null;
  }

  handleClosePatientPopUpClick = () => {
    this.setState({ isClosePatientPopUpShown: true });
  };

  handleCancelPatientClosure = () => {
    this.setState({ isClosePatientPopUpShown: false });
  };

  handleRepeatSearchButtonClick = () => {
    const { match, updateRepeatSearchDate } = this.props;
    const { patientId } = match.params;
    updateRepeatSearchDate(moment(), patientId);
  };
}

export default connector(PatientDashboard);
