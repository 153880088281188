import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { SingleValue } from 'react-select';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { bindActionCreators } from 'redux';
import { LoadingMessage } from '../../../core';
import { isOddNumber } from '../../../core/helpers/arrayHelper';
import { SearchRequestSelectors } from '../../../donorMatchSearchRequests';
import type { ApiSearchRequest } from '../../../donorMatchSearchRequests/types/api';
import type { ReduxState } from '../../../rootReducer';
import { PatientSelectors } from '../../index';
import { searchResultStatuses as searchStatuses } from '../constants/searchResultStatuses';
import * as actions from '../redux/actions';
import Selectors from '../redux/selectors';
import { convertSearchTypeFromApi, convertSearchTypeToNumeric } from '../../../core/constants/searchTypes';

type Props = {
  patientId: string;
  onCancel: (
    event: React.SyntheticEvent<HTMLButtonElement> & {
      currentTarget: HTMLButtonElement;
    }
  ) => void;
};

type OpenSearchesRegistryName = {
  externalRegistryName: string;
};

type PopUpOption = {
  value: string;
  label: string;
};

// "Patient Died" is a hardcoded special case in closure reasons where we want to
// be able to add further details. This same string is also hardcoded in the Patient
// Service
const PatientDiedReason = 'Patient Died';

const stringToSelectOption = (input: string): PopUpOption => ({
  value: input,
  label: input,
});

export const mapSearchRequestsToUniqueSortedRegistryNames = (
  searchRequests: ApiSearchRequest[]
): OpenSearchesRegistryName[] => {
  const openRequestsStatuses = [searchStatuses.initiated.value, searchStatuses.resultsAvailable.value];

  const registryNames = searchRequests
    .filter((search) => !!search.ExternalRegistryDetails)
    .reduce(
      (registries: string[], search: ApiSearchRequest) => [
        ...registries,
        ...search.ExternalRegistryDetails.filter((registry) => openRequestsStatuses.includes(registry.Status)).map(
          (registry) => registry.RegistryName
        ),
      ],
      []
    );

  return _.sortBy(_.uniq(registryNames)).map((name) => ({
    externalRegistryName: name,
  }));
};

const formStyles = {
  border: 'none',
};

const ClosePatientPopUp = (props: Props) => {
  const patientClosureReasons = useSelector(Selectors.getPatientClosureReasons);
  const patientClosureReasonsError = useSelector(Selectors.getFetchPatientClosureReasonErrors);
  const patientClosureReasonsLoading = useSelector(Selectors.getIsLoadingPatientClosureReasons);
  const closingPatientInProgress = useSelector(Selectors.getIsClosingPatient);
  const closingPatientError = useSelector(Selectors.getClosePatientError);
  const patientRegisteredDate = useSelector((state: ReduxState) =>
    Selectors.getPatientRegisteredDate(state, props.patientId)
  );
  const searchRequests = useSelector((state: ReduxState) =>
    PatientSelectors.getPatientSearchRequest(state, props.patientId)
  );
  const searchRequestsLoading = useSelector(SearchRequestSelectors.isFetchingSearchRequests);
  const searchRequestsHasErrored = useSelector(SearchRequestSelectors.hasUserRequestsFetchErrored);
  const searchRequestsErrorMessages = useSelector(SearchRequestSelectors.getRequestsErrorMessage);

  const dispatch = useDispatch();

  const fetchPatientClosureReasons = bindActionCreators(actions.fetchPatientClosureReasons, dispatch);
  const closePatient = bindActionCreators(actions.closePatient, dispatch);

  const [closureReason, setClosureReason] = useState<string | undefined>(undefined);
  const [causeOfDeath, setCauseOfDeath] = useState<string | undefined>(undefined);
  const [dateOfDeath, setDateOfDeath] = useState<string | undefined>(undefined);
  const [patientClosureAttempted, setPatientClosureAttempted] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (patientClosureReasons === undefined) {
      fetchPatientClosureReasons();
    }
  }, [patientClosureReasons]);

  const shouldShowClosureError = () => patientClosureAttempted && !closingPatientInProgress && closingPatientError;

  const isInitError = () => patientClosureReasonsError || searchRequestsHasErrored;

  const isClosureReasonPatientDied = () => closureReason === PatientDiedReason;

  const handleClosureReasonChange = (newValue: SingleValue<PopUpOption>) => {
    const value = newValue?.value;
    setClosureReason(value);
  };

  const getRowClass = (rowIndex: number) => {
    const periodicityClass = isOddNumber(rowIndex) ? 'odd' : 'even';

    return cn('cell', periodicityClass);
  };

  const renderError = () => {
    if (patientClosureReasonsError) {
      return (
        <div id="patientClosureReasonsError">
          <p className="error-message" data-testid="error">
            No patient closure reasons were found
          </p>
          <p className="error-message" data-testid="error">
            {patientClosureReasonsError}
          </p>
        </div>
      );
    }

    if (searchRequestsHasErrored) {
      return (
        <div id="searchRequestsError">
          <p className="error-message" data-testid="error">
            Could not load open search requests for the patient
          </p>
          {searchRequestsErrorMessages.map((errorMessage) => (
            <p className="error-message" data-testid="error">
              {errorMessage}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  const renderClosureReasonControls = () =>
    patientClosureReasons && (
      <>
        <p id="closureReasonsLabel">Select closure reason</p>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          id="closureReasons"
          options={patientClosureReasons.map(stringToSelectOption)}
          onChange={handleClosureReasonChange}
          aria-labelledby="closureReasonsLabel"
        />
        {isClosureReasonPatientDied() && (
          <>
            <label htmlFor="deathCause">
              Cause of death:
              <input
                id="deathCause"
                type="text"
                value={causeOfDeath || ''}
                onChange={(event) => setCauseOfDeath(event.target.value)}
              />
            </label>
            <label htmlFor="deathDate">
              Date of death:
              <input
                id="deathDate"
                type="date"
                value={dateOfDeath || ''}
                onChange={(event) => setDateOfDeath(event.target.value)}
                min={patientRegisteredDate?.format('YYYY-MM-DD')}
                max={moment().format('YYYY-MM-DD')}
              />
            </label>
          </>
        )}
      </>
    );

  const renderOpenSearchRequests = () => {
    const registryNames = searchRequests ? mapSearchRequestsToUniqueSortedRegistryNames(searchRequests) : [];

    const activeRepeatSearches =
      searchRequests?.filter(
        (search) => search.RepeatSearchInfo.Status === 'Active' && search.RepeatSearchInfo.IsFirstRepeatSearch === true
      ) ?? [];

    const convertSearchTypeToNumericValue = (cellData: any) => {
      const convertedRepeatSearches = convertSearchTypeFromApi(cellData);
      const searchTypeToNumeric = convertSearchTypeToNumeric(convertedRepeatSearches);
      return searchTypeToNumeric;
    };
    const availableTableHeight = isClosureReasonPatientDied() ? 200 : 350;
    const registryNamesRows = registryNames.length;
    const activeRepeatSearchesRows = activeRepeatSearches.length;

    const registryNamesMaxHeight = Math.min(availableTableHeight, (registryNamesRows + 1) * 50);
    const activeRepeatSearchesMaxHeight = Math.min(availableTableHeight, (activeRepeatSearchesRows + 1) * 50);
    return (
      <>
        {registryNames.length > 0 && (
          <>
            <p id="emdisSearchesClosureLabel">Are you sure you want to close these EMDIS searches?</p>
            <div id="emdisSearchTableWrapper" style={{ display: 'flex' }}>
              <div style={{ flex: '1 1 auto', height: `${registryNamesMaxHeight}px` }}>
                <AutoSizer>
                  {({ width, height }) => (
                    <Table
                      id="openSearches"
                      className="open-searches-table"
                      width={width}
                      height={height}
                      headerHeight={50}
                      rowHeight={50}
                      rowCount={registryNames.length}
                      rowGetter={({ index }) => registryNames[index]}
                      rowClassName={({ index }) => getRowClass(index)}
                    >
                      <Column
                        label="Emdis Registry"
                        dataKey="externalRegistryName"
                        width={width - 3}
                        cellRenderer={({ cellData }) => cellData}
                      />
                    </Table>
                  )}
                </AutoSizer>
              </div>
            </div>
          </>
        )}
        {activeRepeatSearches.length > 0 && (
          <>
            <p id="repeatSearchesClosureLabel">Are you sure you want to deactivate these UK repeat searches?</p>
            <div id="repeatSearchTableWrapper" style={{ display: 'flex' }}>
              <div style={{ flex: '1 1 auto', height: `${activeRepeatSearchesMaxHeight}px` }}>
                <AutoSizer>
                  {({ width, height }) => (
                    <Table
                      id="activeRepeatSearches"
                      className="open-searches-table"
                      width={width}
                      height={height}
                      headerHeight={50}
                      rowHeight={50}
                      rowCount={activeRepeatSearches.length}
                      rowGetter={({ index }) => activeRepeatSearches[index]}
                      rowClassName={({ index }) => getRowClass(index)}
                    >
                      <Column
                        label="Search Type"
                        dataKey="AdultSearchType"
                        width={width - 3}
                        cellRenderer={({ cellData }) => convertSearchTypeToNumericValue(cellData)}
                      />
                      <Column
                        label="Run Date"
                        dataKey="SearchRunDate"
                        width={width - 3}
                        cellRenderer={({ cellData }) => moment(cellData).format('DD-MM-YYYY')}
                      />
                    </Table>
                  )}
                </AutoSizer>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const submitPatientClosure = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    setPatientClosureAttempted(true);

    if (!closureReason) {
      return;
    }
    if (isClosureReasonPatientDied()) {
      closePatient(props.patientId, closureReason, causeOfDeath, dateOfDeath ? moment.utc(dateOfDeath) : undefined);
    } else {
      closePatient(props.patientId, closureReason, undefined, undefined);
    }
  };

  return (
    <form style={formStyles} onSubmit={submitPatientClosure}>
      {isInitError() ? (
        renderError()
      ) : (
        <>
          <h1 id="closePatientHeader">Close this patient?</h1>
          <LoadingMessage isLoading={patientClosureReasonsLoading || searchRequestsLoading}>
            {renderClosureReasonControls()}
            {renderOpenSearchRequests()}
            {shouldShowClosureError() && (
              <p id="patientClosureError" className="error-message" data-testid="error">
                Something went wrong closing patient: {closingPatientError}
              </p>
            )}
            <div className="btn-actions">
              <button
                className="btn btn--inline btn--search-request"
                disabled={closureReason === undefined || closingPatientInProgress}
                type="submit"
              >
                Close Patient
              </button>
              <button className="btn btn--secondary btn--inline" type="button" onClick={props.onCancel}>
                Close Pop Up
              </button>
            </div>
          </LoadingMessage>
        </>
      )}
    </form>
  );
};

export default ClosePatientPopUp;
