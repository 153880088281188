import update from 'immutability-helper';
import log from 'loglevel';
import {
  ErrorAction as ApiErrorAction,
  get,
  NormalizedPayload,
  post,
  SuccessAction as ApiSuccessAction,
  RequestAction as ApiRequestAction,
  Response,
} from '@an/nova-frontend-rest-client';
import { push } from 'connected-react-router';
// eslint-disable-next-line import/no-cycle
import CurrentReportSelectors from './selectors';
import {
  donorTypes,
  recommendationsList,
  reportTypes,
  searchReport,
  downloadFile,
  urlBuilder,
  delay,
} from '../../../core';
// eslint-disable-next-line import/no-cycle
import { getPatientSearchReports, updateSavedReports } from '../../savedReports/redux/actions';
import Config from '../../../config';
import { getPatientSearchRequest } from '../../../donorMatchSearchRequests';
import type { SavedResultSet } from '../../../donorMatchSearchRequests/types';
import { ReportStatuses } from '../../core/constants';
// eslint-disable-next-line import/no-cycle
import { getRegistryDisplayNames } from '../../core/helpers/getRegistryDisplayNames';
// eslint-disable-next-line import/no-cycle
import { removeReportFromDashboard } from '../../reportDashboard/redux/actions';
import type { Dispatch, GetState } from '../../../core/types';
import type { Registry, CustomRegistry, ReportStatus, SaveReportModel } from '../../types';
import type { ApiRecommendation, ApiSavedReport, ApiSavedReportMinimal, ApiRegistriesForReport } from '../../types/api';
import type { ReduxState } from '../../../rootReducer';
import { getFormattedReportStatus } from '../helpers/getReportStatus';
import { FeatureFlagsSelectors } from '../../../featureFlags';

const CURRENT_REPORT_DETAILS_RESET: 'searchReports/currentReport/reset' = 'searchReports/currentReport/reset';

const CURRENT_REPORT_DOWNLOAD_PDF: 'searchReports/currentReport/pdf/download' =
  'searchReports/currentReport/pdf/download';
const CURRENT_REPORT_DOWNLOAD_PDF_ERROR: 'searchReports/currentReport/pdf/download/error' =
  'searchReports/currentReport/pdf/download/error';
const CURRENT_REPORT_DOWNLOAD_PDF_REQUEST: 'searchReports/currentReport/pdf/download/request' =
  'searchReports/currentReport/pdf/download/request';
const CURRENT_REPORT_DOWNLOAD_PDF_SUCCESS: 'searchReports/currentReport/pdf/download/success' =
  'searchReports/currentReport/pdf/download/success';

const CURRENT_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT: 'searchReports/currentReport/pdf/download/finalAttempt' =
  'searchReports/currentReport/pdf/download/finalAttempt';
const CURRENT_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_ERROR: 'searchReports/currentReport/pdf/download/finalAttempt/error' =
  'searchReports/currentReport/pdf/download/finalAttempt/error';
const CURRENT_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_SUCCESS: 'searchReports/currentReport/pdf/download/finalAttempt/success' =
  'searchReports/currentReport/pdf/download/finalAttempt/success';

const CURRENT_REPORT_GENERATE_PDF: 'searchReports/currentReport/pdf/generate' =
  'searchReports/currentReport/pdf/generate';
const CURRENT_REPORT_GENERATE_PDF_ERROR: 'searchReports/currentReport/pdf/generate/error' =
  'searchReports/currentReport/pdf/generate/error';
const CURRENT_REPORT_GENERATE_PDF_REQUEST: 'searchReports/currentReport/pdf/generate/request' =
  'searchReports/currentReport/pdf/generate/request';
const CURRENT_REPORT_GENERATE_PDF_SUCCESS: 'searchReports/currentReport/pdf/generate/success' =
  'searchReports/currentReport/pdf/generate/success';

const CURRENT_REPORT_SET_REPORT_ON_GOING: 'searchReports/currentReport/reportOnGoing' =
  'searchReports/currentReport/reportOnGoing';

const CURRENT_REPORT_STATUS_UPDATE = 'searchReports/currentReport/status';
const CURRENT_REPORT_STATUS_UPDATE_ERROR: 'searchReports/currentReport/status/error' =
  'searchReports/currentReport/status/error';
const CURRENT_REPORT_STATUS_UPDATE_SUCCESS: 'searchReports/currentReport/status/success' =
  'searchReports/currentReport/status/success';

const REPORT_RECOMMENDATIONS = 'searchReports/currentReport/recommendations';
const CURRENT_REPORT_RECOMMENDATIONS_ERROR: 'searchReports/currentReport/recommendations/error' =
  'searchReports/currentReport/recommendations/error';
const CURRENT_REPORT_RECOMMENDATIONS_FETCH: 'searchReports/currentReport/recommendations/request' =
  'searchReports/currentReport/recommendations/request';
const CURRENT_REPORT_RECOMMENDATIONS_SUCCESS: 'searchReports/currentReport/recommendations/success' =
  'searchReports/currentReport/recommendations/success';

const CURRENT_REPORT_TRANSPLANT_CENTRE = 'searchReports/currentReport/transplantCentre';
const CURRENT_REPORT_TRANSPLANT_CENTRE_ERROR: 'searchReports/currentReport/transplantCentre/error' =
  'searchReports/currentReport/transplantCentre/error';
const CURRENT_REPORT_TRANSPLANT_CENTRE_REQUEST: 'searchReports/currentReport/transplantCentre/request' =
  'searchReports/currentReport/transplantCentre/request';
const CURRENT_REPORT_TRANSPLANT_CENTRE_SUCCESS: 'searchReports/currentReport/transplantCentre/success' =
  'searchReports/currentReport/transplantCentre/success';

const CURRENT_REPORT_TRANSPLANT_CENTRE_EDIT: 'searchReports/currentReport/transplantCentre/edit' =
  'searchReports/currentReport/transplantCentre/edit';

const CURRENT_REPORT_UPDATE_RECEIVED_DATE: 'searchReports/currentReport/receivedDate/update' =
  'searchReports/currentReport/receivedDate/update';

const CURRENT_REPORT_UPDATE_EDITED_RECOMMENDATIONS: 'searchReports/currentReport/editedRecommendations/update' =
  'searchReports/currentReport/editedRecommendations/update';

const CURRENT_REPORT_UPDATE_PRIVATE_NOTES: 'searchReports/currentReport/privateNotes/update' =
  'searchReports/currentReport/privateNotes/update';

const CURRENT_REPORT_UPDATE_REPORT_ID: 'searchReports/currentReport/id/update' =
  'searchReports/currentReport/id/update';

const CURRENT_REPORT_UPDATE_REPORT_TYPE: 'searchReports/currentReport/reportType/update' =
  'searchReports/currentReport/reportType/update';

const CURRENT_REPORT_UPDATE_SELECTED_CORD_SETS: 'searchReports/currentReport/selectedCordSets/update' =
  'searchReports/currentReport/selectedCordSets/update';

const CURRENT_REPORT_UPDATE_SELECTED_DONOR_SETS: 'searchReports/currentReport/selectedDonorSets/update' =
  'searchReports/currentReport/selectedDonorSets/update';

const CURRENT_REPORT_UPDATE_SELECTED_RECOMMENDATIONS: 'searchReports/currentReport/selectedRecommendations/update' =
  'searchReports/currentReport/selectedRecommendations/update';

const CURRENT_REPORT_UPDATE_SELECTED_REGISTRY_NAMES: 'searchReports/currentReport/selectedRegistryDisplayNames/update' =
  'searchReports/currentReport/selectedRegistryDisplayNames/update';

const REPORTED_REGISTRIES: 'searchReports/currentReport/savedSetRegistries' =
  'searchReports/currentReport/savedSetRegistries';
const REPORTED_REGISTRIES_ERROR: 'searchReports/currentReport/savedSetRegistries/error' =
  'searchReports/currentReport/savedSetRegistries/error';
const REPORTED_REGISTRIES_REQUEST: 'searchReports/currentReport/savedSetRegistries/request' =
  'searchReports/currentReport/savedSetRegistries/request';
const REPORTED_REGISTRIES_SUCCESS: 'searchReports/currentReport/savedSetRegistries/success' =
  'searchReports/currentReport/savedSetRegistries/success';

const SAVE_NEW_REPORT = 'searchReports/save';
const SAVE_NEW_REPORT_ERROR: 'searchReports/save/error' = 'searchReports/save/error';
const SAVE_NEW_REPORT_SUCCESS: 'searchReports/save/success' = 'searchReports/save/success';

const SINGLE_SAVED_REPORT = 'searchReports/savedReport';
const SINGLE_SAVED_REPORT_ERROR: 'searchReports/savedReport/error' = 'searchReports/savedReport/error';
const SINGLE_SAVED_REPORT_REQUEST: 'searchReports/savedReport/request' = 'searchReports/savedReport/request';
const SINGLE_SAVED_REPORT_SUCCESS: 'searchReports/savedReport/success' = 'searchReports/savedReport/success';

const TOGGLE_PENDING_CHECKBOX: 'searchReports/savedReport/pending/toggle' = 'searchReports/savedReport/pending/toggle';

const UPDATE_HIDDEN_STATE: 'searchReports/savedReport/hidden/toggle' = 'searchReports/savedReport/hidden/toggle';

const UPDATE_REPORT = 'searchReports/save/update';
const UPDATE_REPORT_ERROR: 'searchReports/save/update/error' = 'searchReports/save/update/error';
const UPDATE_REPORT_SUCCESS: 'searchReports/save/update/success' = 'searchReports/save/update/success';

const UPDATE_CUSTOM_REGISTRIES: 'searchReports/currentReport/customRegistries/update' =
  'searchReports/currentReport/customRegistries/update';

const UPDATE_SEARCHED_REGISTRIES: 'searchReports/currentReport/searchedRegistries/update' =
  'searchReports/currentReport/searchedRegistries/update';

export const Actions = {
  CURRENT_REPORT_DETAILS_RESET,
  CURRENT_REPORT_DOWNLOAD_PDF_ERROR,
  CURRENT_REPORT_DOWNLOAD_PDF_REQUEST,
  CURRENT_REPORT_DOWNLOAD_PDF_SUCCESS,
  CURRENT_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_ERROR,
  CURRENT_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_SUCCESS,
  CURRENT_REPORT_GENERATE_PDF_ERROR,
  CURRENT_REPORT_GENERATE_PDF_REQUEST,
  CURRENT_REPORT_GENERATE_PDF_SUCCESS,
  CURRENT_REPORT_RECOMMENDATIONS_ERROR,
  CURRENT_REPORT_RECOMMENDATIONS_FETCH,
  CURRENT_REPORT_RECOMMENDATIONS_SUCCESS,
  // TODO: Is this still used? Can't find any references
  CURRENT_REPORT_SET_REPORT_ON_GOING,
  CURRENT_REPORT_STATUS_UPDATE_ERROR,
  CURRENT_REPORT_STATUS_UPDATE_SUCCESS,
  CURRENT_REPORT_TRANSPLANT_CENTRE_ERROR,
  CURRENT_REPORT_TRANSPLANT_CENTRE_REQUEST,
  CURRENT_REPORT_TRANSPLANT_CENTRE_SUCCESS,
  CURRENT_REPORT_TRANSPLANT_CENTRE_EDIT,
  CURRENT_REPORT_UPDATE_EDITED_RECOMMENDATIONS,
  CURRENT_REPORT_UPDATE_PRIVATE_NOTES,
  // TODO: Is this still used? Can't find any references
  CURRENT_REPORT_UPDATE_REPORT_ID,
  CURRENT_REPORT_UPDATE_REPORT_TYPE,
  CURRENT_REPORT_UPDATE_SELECTED_CORD_SETS,
  CURRENT_REPORT_UPDATE_SELECTED_DONOR_SETS,
  CURRENT_REPORT_UPDATE_SELECTED_RECOMMENDATIONS,
  CURRENT_REPORT_UPDATE_SELECTED_REGISTRY_NAMES,
  CURRENT_REPORT_UPDATE_RECEIVED_DATE,
  REPORTED_REGISTRIES,
  REPORTED_REGISTRIES_ERROR,
  REPORTED_REGISTRIES_REQUEST,
  REPORTED_REGISTRIES_SUCCESS,
  SAVE_NEW_REPORT_ERROR,
  SAVE_NEW_REPORT_SUCCESS,
  SINGLE_SAVED_REPORT_ERROR,
  SINGLE_SAVED_REPORT_REQUEST,
  SINGLE_SAVED_REPORT_SUCCESS,
  TOGGLE_PENDING_CHECKBOX,
  UPDATE_HIDDEN_STATE,
  UPDATE_REPORT_ERROR,
  UPDATE_REPORT_SUCCESS,
  UPDATE_CUSTOM_REGISTRIES,
  UPDATE_SEARCHED_REGISTRIES,
};

type NewStatusParam = { newStatus: ReportStatus; newIsSelfAuthorised: boolean | undefined };
type PatientIdParam = { patientId: string };
type ReportIdParam = { reportId: string };
type SuccessMessageParam = { successMessage: string };
type ErrorMessageParam = { errorMessage: string };
type Error = { Error: string };

type RecommendationsEntities = {
  Recommendations: { [string: string]: ApiRecommendation };
};
type SavedReportEntities = {
  SearchReports: { [string: string]: ApiSavedReport };
};
type SavedReportEntitiesMinimal = {
  SearchReports: { [string: string]: ApiSavedReportMinimal };
};
type NormalizedReportRecommendationsList = NormalizedPayload<string[], RecommendationsEntities>;
export type NormalizedSavedReport = NormalizedPayload<string, RecommendationsEntities & SavedReportEntities>;
export type NormalizedSavedReportMinimal = NormalizedPayload<
  string,
  RecommendationsEntities & SavedReportEntitiesMinimal
>;

export type SavedReportId = string;

export type CurrentReportGeneratePdfErrorAction = ApiErrorAction<
  typeof CURRENT_REPORT_GENERATE_PDF_ERROR,
  ReportIdParam & ErrorMessageParam,
  Error
>;
export type CurrentReportGeneratePdfRequestAction = ApiSuccessAction<
  typeof CURRENT_REPORT_GENERATE_PDF_REQUEST,
  ReportIdParam
>;
export type CurrentReportGeneratePdfSuccessAction = ApiSuccessAction<
  typeof CURRENT_REPORT_GENERATE_PDF_SUCCESS,
  ReportIdParam & SuccessMessageParam,
  SuccessMessageParam & string
>;

export type CurrentReportDownloadPdfErrorAction = ApiErrorAction<
  typeof Actions.CURRENT_REPORT_DOWNLOAD_PDF_ERROR,
  ReportIdParam & ErrorMessageParam,
  Error
>;
export type CurrentReportDownloadPdfRequestAction = ApiRequestAction<
  typeof CURRENT_REPORT_DOWNLOAD_PDF_REQUEST,
  Record<string, unknown>
>;
export type CurrentReportDownloadPdfSuccessAction = ApiSuccessAction<
  typeof CURRENT_REPORT_DOWNLOAD_PDF_SUCCESS,
  SuccessMessageParam,
  SuccessMessageParam
>;
export type CurrentReportDownloadPdfFinalAttemptErrorAction = ApiErrorAction<
  typeof CURRENT_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_ERROR,
  ErrorMessageParam,
  Record<string, unknown>
>;
export type CurrentReportDownloadPdfFinalAttemptSuccessAction = ApiSuccessAction<
  typeof CURRENT_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT_SUCCESS,
  SuccessMessageParam,
  SuccessMessageParam
>;

export type CurrentReportRecommendationsErrorAction = ApiErrorAction<
  typeof CURRENT_REPORT_RECOMMENDATIONS_ERROR,
  Record<string, unknown>
>;
export type CurrentReportRecommendationsFetchAction = ApiErrorAction<
  typeof CURRENT_REPORT_RECOMMENDATIONS_FETCH,
  Record<string, unknown>
>;
export type CurrentReportRecommendationsSuccessAction = ApiSuccessAction<
  typeof CURRENT_REPORT_RECOMMENDATIONS_SUCCESS,
  Record<string, unknown>,
  NormalizedReportRecommendationsList
>;
export type CurrentReportSetReportOnGoingAction = ApiSuccessAction<
  typeof CURRENT_REPORT_SET_REPORT_ON_GOING,
  ReportIdParam
>;
export type CurrentReportStatusUpdateErrorAction = ApiErrorAction<
  typeof CURRENT_REPORT_STATUS_UPDATE_ERROR,
  ReportIdParam & ErrorMessageParam,
  Error
>;
export type CurrentReportStatusUpdateSuccessAction = ApiSuccessAction<
  typeof CURRENT_REPORT_STATUS_UPDATE_SUCCESS,
  NewStatusParam & ReportIdParam & SuccessMessageParam,
  void & SuccessMessageParam
>;
export type CurrentReportTransplantCentreEditAction = {
  type: typeof CURRENT_REPORT_TRANSPLANT_CENTRE_EDIT;
  payload: string[];
};
export type CurrentReportTransplantCentreErrorAction = ApiErrorAction<
  typeof CURRENT_REPORT_TRANSPLANT_CENTRE_ERROR,
  PatientIdParam
>;
export type CurrentReportTransplantCentreRequestAction = ApiSuccessAction<
  typeof CURRENT_REPORT_TRANSPLANT_CENTRE_REQUEST,
  PatientIdParam
>;
export type CurrentReportUpdateReportIdAction = ApiSuccessAction<typeof CURRENT_REPORT_UPDATE_REPORT_ID, ReportIdParam>;
export type CurrentReportTransplantCentreSuccessAction = ApiSuccessAction<
  typeof CURRENT_REPORT_TRANSPLANT_CENTRE_SUCCESS,
  PatientIdParam,
  {
    TransplantCentreAddress: string[];
  }
>;
export type CurrentReportUpdateEditedRecommendationsAction = {
  type: typeof CURRENT_REPORT_UPDATE_EDITED_RECOMMENDATIONS;
  payload: {
    editedRecommendation: string;
    id: string;
  };
};
export type CurrentReportUpdatePrivateNotesAction = {
  type: typeof CURRENT_REPORT_UPDATE_PRIVATE_NOTES;
  payload: string;
};
export type CurrentReportUpdateReceivedDateAction = {
  type: typeof CURRENT_REPORT_UPDATE_RECEIVED_DATE;
  payload: Date;
};
export type CurrentReportUpdateReportTypeAction = {
  type: typeof CURRENT_REPORT_UPDATE_REPORT_TYPE;
  payload?: string;
};
export type CurrentReportUpdateSelectedCordsAction = {
  type: typeof CURRENT_REPORT_UPDATE_SELECTED_CORD_SETS;
  payload: string[];
};
export type CurrentReportUpdateSelectedDonorSetAction = {
  type: typeof CURRENT_REPORT_UPDATE_SELECTED_DONOR_SETS;
  payload: string[];
};
export type CurrentReportUpdateSelectedRecommendationsAction = {
  type: typeof CURRENT_REPORT_UPDATE_SELECTED_RECOMMENDATIONS;
  payload: string[];
};
export type CurrentReportUpdateSelectedRegistryDisplayNamesAction = {
  type: typeof CURRENT_REPORT_UPDATE_SELECTED_REGISTRY_NAMES;
  payload: (string | undefined)[];
};
export type ReportedRegistriesSuccessAction = ApiSuccessAction<
  typeof REPORTED_REGISTRIES_SUCCESS,
  Record<string, unknown>,
  ApiRegistriesForReport
>;
export type ReportedRegistriesRequestAction = ApiRequestAction<
  typeof REPORTED_REGISTRIES_REQUEST,
  Record<string, unknown>
>;
export type SaveNewReportSuccessAction = ApiSuccessAction<
  typeof SAVE_NEW_REPORT_SUCCESS,
  SuccessMessageParam,
  SavedReportId & SuccessMessageParam
>;
export type SaveNewReportErrorAction = ApiErrorAction<typeof SAVE_NEW_REPORT_ERROR, ErrorMessageParam, Error>;

export type SingleSavedReportErrorAction = ApiErrorAction<typeof SINGLE_SAVED_REPORT_ERROR, Record<string, unknown>>;
export type SingleSavedReportSuccessAction = ApiSuccessAction<
  typeof SINGLE_SAVED_REPORT_SUCCESS,
  Record<string, unknown>,
  NormalizedSavedReport
>;
export type UpdateReportErrorAction = ApiErrorAction<
  typeof UPDATE_REPORT_ERROR,
  ReportIdParam & ErrorMessageParam,
  Error
>;
export type UpdateReportSuccessAction = ApiSuccessAction<
  typeof UPDATE_REPORT_SUCCESS,
  SuccessMessageParam,
  SuccessMessageParam
>;

export type CurrentReportDetailsResetAction = {
  type: typeof Actions.CURRENT_REPORT_DETAILS_RESET;
};

export type TogglePendingCheckboxAction = {
  type: typeof Actions.TOGGLE_PENDING_CHECKBOX;
  payload: {
    id: string;
    isPending: boolean;
    isNewReport: boolean;
  };
};

export type UpdateHiddenStateAction = {
  type: typeof Actions.UPDATE_HIDDEN_STATE;
  payload: {
    id: string;
    isHidden: boolean;
    isNewReport: boolean;
  };
};

export type UpdateCustomRegistriesAction = {
  type: typeof UPDATE_CUSTOM_REGISTRIES;
  payload: CustomRegistry[];
};

export type UpdateSearchedRegistriesAction = {
  type: typeof UPDATE_SEARCHED_REGISTRIES;
  payload: Partial<Registry>[];
};

export const updateReceivedDate = (receivedDate: Date) => ({
  type: Actions.CURRENT_REPORT_UPDATE_RECEIVED_DATE,
  payload: receivedDate,
});

export const editTransplantCentre = (transplantCentre: string[]) => ({
  type: Actions.CURRENT_REPORT_TRANSPLANT_CENTRE_EDIT,
  payload: transplantCentre,
});

export const resetReportDetails = (): CurrentReportDetailsResetAction => ({
  type: Actions.CURRENT_REPORT_DETAILS_RESET,
});

export const updateEditedRecommendations = (editedRecommendation: {
  editedRecommendation: string;
  id: string;
}): CurrentReportUpdateEditedRecommendationsAction => ({
  type: Actions.CURRENT_REPORT_UPDATE_EDITED_RECOMMENDATIONS,
  payload: editedRecommendation,
});

export const updatePrivateNotes = (notes: string): CurrentReportUpdatePrivateNotesAction => ({
  type: Actions.CURRENT_REPORT_UPDATE_PRIVATE_NOTES,
  payload: notes,
});

export const updateReportType = (reportType?: string): CurrentReportUpdateReportTypeAction => ({
  type: Actions.CURRENT_REPORT_UPDATE_REPORT_TYPE,
  payload: reportType,
});

const updateSelectedCordSets = (cordSets: string[]): CurrentReportUpdateSelectedCordsAction => ({
  type: Actions.CURRENT_REPORT_UPDATE_SELECTED_CORD_SETS,
  payload: cordSets,
});

const updateSelectedDonorSets = (donorSets: string[]): CurrentReportUpdateSelectedDonorSetAction => ({
  type: Actions.CURRENT_REPORT_UPDATE_SELECTED_DONOR_SETS,
  payload: donorSets,
});

const updateSelectedRecommendations = (
  recommendations: string[]
): CurrentReportUpdateSelectedRecommendationsAction => ({
  type: Actions.CURRENT_REPORT_UPDATE_SELECTED_RECOMMENDATIONS,
  payload: recommendations,
});

const updateRegistryDisplayNames = (
  registryDisplayNames: (string | undefined)[]
): CurrentReportUpdateSelectedRegistryDisplayNamesAction => ({
  type: Actions.CURRENT_REPORT_UPDATE_SELECTED_REGISTRY_NAMES,
  payload: registryDisplayNames,
});

const updateCustomRegistries = (registries: CustomRegistry[]): UpdateCustomRegistriesAction => ({
  type: UPDATE_CUSTOM_REGISTRIES,
  payload: registries,
});

export const updateSearchedRegistries = (registries: Partial<Registry>[]): UpdateSearchedRegistriesAction => ({
  type: UPDATE_SEARCHED_REGISTRIES,
  payload: registries,
});

export const getRegistriesForReport = (setIds: string[], patientId: string, reportId: string | null) => {
  const reportIdParam = reportId ? `&reportId=${reportId}` : '';
  const setIdsParam = setIds.length > 0 ? setIds.map((id) => `&setId=${id}`).join('') : '';
  return get<ReduxState, void>(
    `${Config().apiBaseUrl}search-reports/registries-for-report/?patientId=${patientId}${setIdsParam}${reportIdParam}`,
    REPORTED_REGISTRIES,
    {
      patientId,
    }
  );
};

const getReportData = (reportId: string) =>
  get<ReduxState, NormalizedSavedReportMinimal>(
    `${Config().apiBaseUrl}search-reports/${reportId}`,
    SINGLE_SAVED_REPORT,
    { reportId },
    searchReport
  );

const postNewSearchReport =
  (report: SaveReportModel, reportType: string, patientId: string) => async (dispatch: Dispatch<any>) => {
    const savedReport: Response<string> = await dispatch(
      post<ReduxState, string>(
        `${Config().apiBaseUrl}search-reports`,
        SAVE_NEW_REPORT,
        {
          successMessage: 'Search Report saved',
          errorMessage: 'Search Report not saved',
        },
        report
      )
    );
    if (savedReport.response.ok && savedReport.response.body) {
      const newReportId = savedReport.response.body;
      const newReportResponse: Response<NormalizedSavedReport> = await dispatch(getReportData(newReportId));
      if (newReportResponse.response.ok && newReportResponse.response.body) {
        const newReport = newReportResponse.response.body;
        if (reportType === reportTypes.international) {
          dispatch(
            getRegistriesForReport(
              newReport.entities.SearchReports[newReportId].SelectedDonorSetsIds,
              patientId,
              newReportId
            )
          );
        }
        dispatch(updateSavedReports(newReportResponse));
        dispatch(push(`/patient/${patientId}/reports/${newReport.result}?reportType=${reportType}`));
      } else {
        log.error(`Failed to fetch new report data - ${newReportResponse.response.status}`);
      }
    } else {
      log.error(`Report not saved - ${savedReport.response.status}`);
    }
  };

const updateReport = (reportId: string, editedReport: Record<string, unknown>) =>
  post<ReduxState, void>(
    `${Config().apiBaseUrl}search-reports/${reportId}`,
    UPDATE_REPORT,
    {
      reportId,
      successMessage: 'Search Report Updated',
      errorMessage: 'Search Report Not Updated',
    },
    editedReport
  );

export const updateHiddenState = (id: string, isHidden: boolean, isNewReport: boolean) => ({
  type: UPDATE_HIDDEN_STATE,
  payload: {
    id,
    isHidden,
    isNewReport,
  },
});

export const handlePendingCheckbox = (id: string, isPending: boolean, isNewReport: boolean) => ({
  type: TOGGLE_PENDING_CHECKBOX,
  payload: {
    id,
    isPending,
    isNewReport,
  },
});

const deselectItem = (currentSelectionIdsList: string[], selectionItemId: string) => {
  const selectionIndex = currentSelectionIdsList.indexOf(selectionItemId);
  return update(currentSelectionIdsList, { $splice: [[selectionIndex, 1]] });
};

const updateSelectedItemIds = (previousSelectedItemIds: string[], newlySelectedItemId: string) => {
  if (previousSelectedItemIds.includes(newlySelectedItemId)) {
    return deselectItem(previousSelectedItemIds, newlySelectedItemId);
  }

  return update(previousSelectedItemIds, { $push: [newlySelectedItemId] });
};

export const updateDonorReportSearchSummary =
  (toggledDonorSetId: string, allSavedDonorSets: { [string: string]: SavedResultSet }, reportType: string) =>
  (dispatch: Dispatch<any>, getState: GetState) => {
    const oldSelectedDonorIds = CurrentReportSelectors.getSelectedResultSetIds(getState(), donorTypes.adult.value);
    const newSelectedDonorIds = updateSelectedItemIds(oldSelectedDonorIds, toggledDonorSetId);
    if (reportType === reportTypes.international) {
      dispatch(
        updateRegistryDisplayNames(
          getRegistryDisplayNames(
            newSelectedDonorIds,
            allSavedDonorSets,
            FeatureFlagsSelectors.getCurrentFeatureFlags(getState())
              .searchReportsIsRegistryDisplayNameUsedForNewReports ?? false
          )
        )
      );
    }
    dispatch(updateSelectedDonorSets(newSelectedDonorIds));
  };

export const updateCordReportSearchSummary =
  (toggledCordSetId: string) => (dispatch: Dispatch<any>, getState: GetState) => {
    const oldSelectedCordIds = CurrentReportSelectors.getSelectedResultSetIds(getState(), donorTypes.cord.value);
    const newSelectedCordIds = updateSelectedItemIds(oldSelectedCordIds, toggledCordSetId);
    dispatch(updateSelectedCordSets(newSelectedCordIds));
  };

const getRecommendations = () =>
  get<ReduxState, NormalizedReportRecommendationsList>(
    `${Config().apiBaseUrl}search-reports/recommendations`,
    REPORT_RECOMMENDATIONS,
    undefined,
    recommendationsList
  );

export const updateSelectedRegistryDisplayNames =
  (registryDisplayNames: (string | undefined)[]) => (dispatch: Dispatch<any>) => {
    dispatch(updateRegistryDisplayNames(registryDisplayNames));
  };

export const setCustomRegistries = (registries: CustomRegistry[]) => (dispatch: Dispatch<any>) => {
  dispatch(updateCustomRegistries(registries));
};

export const setSelectedRecommendations =
  (recommendationId: string) => (dispatch: Dispatch<any>, getState: GetState) => {
    const oldSelectedRecommendationIds = CurrentReportSelectors.getSelectedRecommendations(getState());
    const newSelectedRecommendationIds = updateSelectedItemIds(oldSelectedRecommendationIds, recommendationId);
    dispatch(updateSelectedRecommendations(newSelectedRecommendationIds));
  };

export const saveSearchReport =
  (patientId: string, reportId: string, reportType: string) => (dispatch: Dispatch<any>, getState: GetState) => {
    if (reportId === 'new') {
      const report = CurrentReportSelectors.makeNewReportObject(patientId, getState(), reportType);
      dispatch(postNewSearchReport(report, reportType, patientId));
    } else {
      const report = CurrentReportSelectors.getCurrentReportObject(getState(), reportType);
      dispatch(updateReport(reportId, report));
    }
  };

const getPatientsTransplantCentre = (patientId: string) =>
  get<ReduxState, { TransplantCentreAddress: string[] }>(
    `${Config().apiBaseUrl}patients/${patientId}/transplant-centre`,
    CURRENT_REPORT_TRANSPLANT_CENTRE,
    { patientId }
  );

const makeAllRequests = (patientId: string) => (dispatch: Dispatch<any>) =>
  Promise.all([
    dispatch(getPatientSearchRequest(patientId)),
    dispatch(getRecommendations()),
    dispatch(getPatientsTransplantCentre(patientId)),
  ]);

export const getReportGenerationPageData = (patientId: string) => makeAllRequests(patientId);

export const getSavedReport = (reportId: string, patientId: string) => (dispatch: Dispatch<any>) =>
  Promise.all([
    dispatch(getReportData(reportId)),
    dispatch(getPatientSearchRequest(patientId)),
    dispatch(getPatientSearchReports(patientId)),
  ]);

const changeReportStatus = (reportId: string, newStatus: ReportStatus, newIsSelfAuthorised: boolean | undefined) =>
  post<ReduxState, void>(
    `${Config().apiBaseUrl}search-reports/${reportId}/status/${newStatus}`,
    CURRENT_REPORT_STATUS_UPDATE,
    {
      reportId,
      newStatus,
      newIsSelfAuthorised,
      successMessage: `Report Status Updated - ${getFormattedReportStatus(newStatus, newIsSelfAuthorised)}`,
    },
    { isSelfAuthorised: newIsSelfAuthorised }
  );

const updateReportAndChangeStatus =
  (reportId: string, reportType: string, newStatus: ReportStatus, newIsSelfAuthorised: boolean | undefined) =>
  async (dispatch: Dispatch<any>, getState: GetState) => {
    const report = CurrentReportSelectors.getCurrentReportObject(getState(), reportType);
    const editedReport = await dispatch(updateReport(reportId, report));

    if (editedReport.response.ok) {
      dispatch(changeReportStatus(reportId, newStatus, newIsSelfAuthorised));
    } else {
      log.error(`Report ${reportId} could not be updated`);
    }
  };

export const generateReportPdf = (reportId: string) =>
  post<ReduxState, string>(
    `${Config().apiBaseUrl}search-reports/${reportId}/generate-pdf`,
    CURRENT_REPORT_GENERATE_PDF,
    {
      reportId,
      successMessage: 'Report PDF is being generated...',
      errorMessage: 'Report PDF Not Generated',
    }
  );

const startReportPdfGeneration =
  (reportId: string, newStatus: ReportStatus, newIsSelfAuthorised: boolean | undefined) =>
  async (dispatch: Dispatch<any>) => {
    const reportStatus = await dispatch(changeReportStatus(reportId, newStatus, newIsSelfAuthorised));

    if (reportStatus.response.ok) {
      dispatch(generateReportPdf(reportId));
    } else {
      log.error(`Report status change failed - ${reportStatus.response.status}`);
    }
  };

const updateReportAndGeneratePdf =
  (reportId: string, reportType: string, newStatus: ReportStatus, newIsSelfAuthorised: boolean | undefined) =>
  async (dispatch: Dispatch<any>, getState: GetState) => {
    const report = CurrentReportSelectors.getCurrentReportObject(getState(), reportType);
    const editedReport = await dispatch(updateReport(reportId, report));

    if (editedReport.response.ok) {
      dispatch(startReportPdfGeneration(reportId, newStatus, newIsSelfAuthorised));
    } else {
      log.error(`Report ${reportId} could not be updated`);
    }
  };

export const editSearchReportStatus =
  (reportId: string, reportType: string, newStatus: ReportStatus, newIsSelfAuthorised: boolean | undefined) =>
  (dispatch: Dispatch<any>) => {
    if (newStatus === ReportStatuses.Authorised && newIsSelfAuthorised === true) {
      dispatch(updateReportAndGeneratePdf(reportId, reportType, newStatus, newIsSelfAuthorised));
    } else {
      switch (newStatus) {
        case ReportStatuses.AwaitingAuthorisation: {
          dispatch(updateReportAndChangeStatus(reportId, reportType, newStatus, newIsSelfAuthorised));
          break;
        }
        case ReportStatuses.Authorised: {
          dispatch(startReportPdfGeneration(reportId, newStatus, newIsSelfAuthorised));
          break;
        }
        case ReportStatuses.Sent:
        case ReportStatuses.Draft:
        case ReportStatuses.Deleted: {
          dispatch(changeReportStatus(reportId, newStatus, newIsSelfAuthorised));
          break;
        }
        default:
          break;
      }
    }
  };

export const deleteReport = (reportId: string) => async (dispatch: Dispatch<any>) => {
  await dispatch(changeReportStatus(reportId, ReportStatuses.Deleted, undefined));
  dispatch(removeReportFromDashboard(reportId));
};

export const downloadPdfReport = (filename: string) => async (dispatch: Dispatch<any>) => {
  // Firstly we check the status of the PDF generation process (is there a PDF ot not)
  const pdfStatusUrl = urlBuilder('reports/pdf/:filename/status', { filename });

  let reportPdf;
  const downloadAttempts = Config().reportDownloadAttempts;
  // eslint-disable-next-line no-plusplus
  for (let attempt = 1; attempt <= downloadAttempts; attempt++) {
    // eslint-disable-next-line no-await-in-loop
    reportPdf = await dispatch(
      get(
        pdfStatusUrl,
        attempt === downloadAttempts ? CURRENT_REPORT_DOWNLOAD_PDF_FINAL_ATTEMPT : CURRENT_REPORT_DOWNLOAD_PDF,
        {
          filename,
          successMessage: 'Report PDF has been downloaded',
          errorMessage: 'An error has occurred when downloading report PDF',
        }
      )
    );

    if (reportPdf.response.ok) {
      break;
    }

    // eslint-disable-next-line no-await-in-loop
    await delay(Config().reportDownloadWaitBetweenAttemptsSec * 1000);
  }

  // If the response is not Ok (ie. the generation process has not finished)
  // we log an error and tell the User through a notification.
  if (!reportPdf.response.ok) {
    return log.error('Error while fetching report PDF');
  }

  // If there is a PDF, we download it for the User to view.
  const pdfDownloadUrl = urlBuilder('reports/pdf/:filename', { filename });
  downloadFile(pdfDownloadUrl, filename, 'pdf');

  return undefined;
};
