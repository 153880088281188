import React from 'react';
import moment from 'moment';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { connect, ConnectedProps } from 'react-redux';

import { AnyAction, bindActionCreators, Dispatch as ReduxDispatch } from 'redux';
import classNames from 'classnames';
import { algorithmTypes, Button, donorTypes, LoadingMessage, NoResultsFound, reportTypes } from '../../../../../core';
import { PatientSelectors } from '../../../../index';
import * as actions from '../../../redux/actions';

import UserSelectors from '../../../../../currentUser/redux/selectors';
import { isOddNumber } from '../../../../../core/helpers/arrayHelper';
import type { ApiSearchRequest, ApiSearchRequestSummary } from '../../../../../donorMatchSearchRequests/types/api';
import type { ReduxState } from '../../../../../rootReducer';
import resultSetTypes from './resultSetTypes';
import type { DonorType } from '../../../../../core/types';
import { convertSearchTypeFromApiToNumeric, type ApiSearchType } from '../../../../../core/constants/searchTypes';
import { getSingleSearchRequest } from '../../../../../donorMatchSearchRequests/redux/actions';
import { SearchRequestSelectors } from '../../../../../donorMatchSearchRequests';
import { algorithmNames, convertToAlgorithmName } from '../../../helpers/algorithmConverter';
import { FeatureFlag, FeatureFlagsSelectors, Features } from '../../../../../featureFlags';
import {
  ApiRepeatSearchStatus,
  convertRepeatSearchStatusFromApi,
  RepeatSearchStatuses,
} from '../../../../../core/constants/repeatSearchStatuses';
import { UserLevel, UserLevelNumber } from '../../../../../currentUser/constants';

type OwnProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  patientId: string; // used in `mapStateToProps`
  isPatientClosed: boolean | null | undefined;
};
type StateProps = {
  individualSearchRequestsBeingFetched: string[];
  userLevel?: UserLevel;
  searchRequests?: ApiSearchRequest[];
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  shouldShowNovaResults?: boolean;
  featureFlags: Features;
};
type Props = PropsFromRedux & OwnProps & StateProps & RouteComponentProps<{ requestIdParam: string }>;
type State = {
  autoRequestFetchingStopped: boolean;
  isRepeatSearchActivating: {
    [key: string]: boolean;
  };
  isReactivatingRepeatSearch: {
    [key: string]: boolean;
  };
  isRetryingFailedSearch: {
    [key: string]: boolean;
  };
};
type SearchRequestTableData = {
  algorithm: string;
  algorithmCount: number;
  compositeId: string;
  donorType: DonorType;
  id: string;
  repeatSearchInformation: {
    isFirstRepeatSearch: boolean;
    isOriginalSearch: boolean;
    originalSearchRequestId: string;
    firstRepeatSearchRequestId: string;
    status: ApiRepeatSearchStatus;
  };
  registries: string;
  resultsLinkData: {
    requestId: string;
    resultSetStatus: string;
    url: string;
  };
  runDate: string;
  runBy: string;
  adultSearchType: ApiSearchType;
  cordSearchType: ApiSearchType;
  status: string;
  validationUrl: string;
};

const mapStateToProps = (state: ReduxState, ownProps: OwnProps): StateProps => ({
  individualSearchRequestsBeingFetched: SearchRequestSelectors.individualSearchRequestsBeingFetched(state),
  userLevel: UserSelectors.getCurrentUserLevel(state),
  searchRequests: PatientSelectors.getPatientSearchRequest(state, ownProps.patientId),
  featureFlags: FeatureFlagsSelectors.getCurrentFeatureFlags(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<AnyAction>) => ({
  fetchSearchRequest: bindActionCreators(getSingleSearchRequest, dispatch),
  activateRepeatSearch: bindActionCreators(actions.activateRepeatSearch, dispatch),
  reActivateRepeatSearch: bindActionCreators(actions.reActivateRepeatSearch, dispatch),
  retryFailedOriginalSearch: bindActionCreators(actions.retryFailedOriginalSearch, dispatch),
  retryFailedRepeatSearch: bindActionCreators(actions.retryFailedRepeatSearch, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const formatInternationalRegistries = (donorType: DonorType, search: ApiSearchRequestSummary) => {
  if (donorType === donorTypes.cord.value) {
    return search.EmdisCordRegistriesSearched.length > 0
      ? search.EmdisCordRegistriesSearched.join(' ')
      : 'No international registries';
  }
  return 'N/A';
};

const mapAllSearches = (searchRequests: ApiSearchRequest[]): SearchRequestTableData[] =>
  searchRequests
    .filter(
      (search) =>
        !search.ExternalRegistryDetails ||
        (search.ExternalRegistryDetails && search.ExternalRegistryDetails.length === 0)
    )
    .map((search) =>
      search.ResultSetSummaries.map((resultSet): SearchRequestTableData => {
        const setId = resultSet.SearchResultSetId;
        const donorType = resultSet.DonorType;
        const url = `/donorsearch/${search.Id}/results?resultSetId=${setId}&donorType=${donorType}&reportType=${reportTypes.internal}`;
        const validationUrl = `/donorsearch/${search.Id}/algorithmComparison?donorType=${donorType}`;

        const status = resultSet.AlgorithmUsed === algorithmTypes.solar ? search.Status : resultSet.Status;

        const registries = formatInternationalRegistries(donorType, search);
        return {
          algorithm: convertToAlgorithmName(resultSet.AlgorithmUsed),
          algorithmCount: search.AlgorithmsUsed.length,
          compositeId: `${setId}-${search.Id}`,
          donorType,
          id: search.Id,
          repeatSearchInformation: {
            isFirstRepeatSearch: search.RepeatSearchInfo.IsFirstRepeatSearch,
            isOriginalSearch: search.RepeatSearchInfo.IsOriginalSearch,
            originalSearchRequestId: search.RepeatSearchInfo.OriginalSearchRequestId,
            firstRepeatSearchRequestId: search.RepeatSearchInfo.FirstRepeatSearchRequestId,
            status: search.RepeatSearchInfo.Status,
          },
          registries,
          resultsLinkData: {
            requestId: search.Id,
            resultSetStatus: resultSet.Status,
            url,
          },
          runBy: search.RequestedByUser,
          runDate: search.SearchRunDate,
          adultSearchType: search.AdultSearchType,
          cordSearchType: search.CordSearchType,
          status,
          validationUrl,
        };
      })
    )
    // $FlowExpectedError - https://github.com/facebook/flow/issues/7397
    .flat();

const validationLink = (compositeId: string, searchRequests: SearchRequestTableData[], userLevel?: UserLevel) => {
  const searchRequest = searchRequests.find((x) => x.compositeId === compositeId);

  if (searchRequest) {
    const { algorithmCount, id, validationUrl } = searchRequest;

    return algorithmCount > 1 && userLevel != null && userLevel >= UserLevelNumber.LevelFour ? (
      <Link to={validationUrl}>{id}</Link>
    ) : (
      searchRequest.id
    );
  }

  return null;
};

class ResultsTable extends React.PureComponent<Props, State> {
  pollForSearchResultsTimeOut!: NodeJS.Timeout;

  static defaultProps = {
    searchRequests: [],
    userLevel: undefined,
  };

  state: State = {
    autoRequestFetchingStopped: false,
    isRepeatSearchActivating: {},
    isReactivatingRepeatSearch: {},
    isRetryingFailedSearch: {},
  };

  componentDidMount(): void {
    this.pollForSearchResults();
  }

  componentWillUnmount(): void {
    clearTimeout(this.pollForSearchResultsTimeOut);
  }

  render() {
    const { searchRequests, userLevel, patientId, isPatientClosed, match } = this.props;
    const { requestIdParam } = match.params;
    const { autoRequestFetchingStopped, isRepeatSearchActivating, isReactivatingRepeatSearch, isRetryingFailedSearch } =
      this.state;

    const resultPageLink = (
      {
        url,
        resultSetStatus,
        requestId,
      }: {
        url: string;
        resultSetStatus: string;
        requestId: string;
      },
      rowData: {
        id: string;
        algorithm: string;
        donorType: string;
        repeatSearchInformation: {
          isFirstRepeatSearch: boolean;
          isOriginalSearch: boolean;
          status: string;
        };
        status: string;
      }
    ) => {
      switch (resultSetStatus) {
        case resultSetTypes.initiated:
          return autoRequestFetchingStopped ? (
            <div style={{ fontSize: 12 }}>
              Not loaded - please {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="" onClick={() => window.location.reload()}>
                refresh
              </a>
            </div>
          ) : (
            <LoadingMessage isButton />
          );
        case resultSetTypes.failed:
          if (rowData.algorithm === algorithmNames.ANSearchVersion2) {
            return (
              <Button
                onClick={() =>
                  this.handleRetryFailedSearchButtonClick(
                    requestId,
                    patientId,
                    rowData.donorType,
                    rowData.repeatSearchInformation.isFirstRepeatSearch
                  )
                }
                text="Retry"
                buttonClass="btn btn--uk-search-actions"
                loading={isRetryingFailedSearch[`${requestId}-${rowData.donorType}`]}
              />
            );
          }
          return null;
        case resultSetTypes.complete:
        default:
          return (
            <>
              <Link to={url} className="btn btn--uk-search-actions">
                View Results
              </Link>
              {rowData.algorithm === algorithmNames.ANSearchVersion2 &&
                rowData.donorType === donorTypes.adult.value &&
                !rowData.repeatSearchInformation.isFirstRepeatSearch && (
                  <FeatureFlag
                    flag="repeatSearchEnabled"
                    render={() => (
                      <Button
                        onClick={() => this.handleActivateRepeatSearchButtonClick(requestId, patientId)}
                        text="Activate Repeat Search"
                        buttonClass="btn btn--uk-search-actions"
                        loading={isRepeatSearchActivating[requestId]}
                        disabled={
                          rowData.repeatSearchInformation.isOriginalSearch || isRepeatSearchActivating[requestId]
                        }
                      />
                    )}
                  />
                )}
              {!isPatientClosed &&
                rowData.repeatSearchInformation.isFirstRepeatSearch &&
                rowData.repeatSearchInformation.status === RepeatSearchStatuses.Deactivated && (
                  <Button
                    onClick={() => this.handleReactivateDeactivatedRepeatSearchButtonClick(requestId, patientId)}
                    text="Activate"
                    buttonClass="btn btn--uk-search-actions"
                    loading={isReactivatingRepeatSearch[requestId]}
                  />
                )}
            </>
          );
      }
    };

    const mappedSearchRequests = searchRequests ? mapAllSearches(searchRequests) : [];

    const sortedRequestsByDate: SearchRequestTableData[] = mappedSearchRequests.sort(
      (a, b) => moment(b.runDate).valueOf() - moment(a.runDate).valueOf()
    );

    if (mappedSearchRequests.length === 0) {
      return <NoResultsFound resultType="requests" />;
    }

    const getRowHeight = ({ index }: { index: number }) => {
      const rowData = sortedRequestsByDate[index];
      const { featureFlags } = this.props;

      const isFeatureFlagEnabled = (features: Features): boolean => features?.repeatSearchEnabled ?? false;
      const featureFlagEnabled = isFeatureFlagEnabled(featureFlags);

      const isSearchRepeatable =
        !rowData.repeatSearchInformation.isFirstRepeatSearch &&
        rowData.algorithm === algorithmNames.ANSearchVersion2 &&
        rowData.donorType === donorTypes.adult.value &&
        rowData.status !== resultSetTypes.failed &&
        featureFlagEnabled;

      const isFirstRepeatSearchDeactivated =
        rowData.repeatSearchInformation.isFirstRepeatSearch &&
        rowData.repeatSearchInformation.status === RepeatSearchStatuses.Deactivated;

      if (isSearchRepeatable || isFirstRepeatSearchDeactivated) {
        return 90;
      }

      return 50;
    };

    const getSearchType = (rowData: SearchRequestTableData) => {
      if (rowData.donorType === donorTypes.cord.value) {
        return convertSearchTypeFromApiToNumeric(rowData.cordSearchType);
      }
      const convertedAdultSearchType = convertSearchTypeFromApiToNumeric(rowData.adultSearchType as ApiSearchType);
      const adultSearchType = rowData.repeatSearchInformation.isFirstRepeatSearch
        ? `Repeat ${convertedAdultSearchType}`
        : convertedAdultSearchType;
      return adultSearchType;
    };

    const statusCellData = (rowData: SearchRequestTableData) => {
      const { status, repeatSearchInformation } = rowData;
      if (repeatSearchInformation.isFirstRepeatSearch) {
        return convertRepeatSearchStatusFromApi(repeatSearchInformation.status);
      }
      return status;
    };

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '1 1 auto', height: '400px' }}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="results-table"
                width={width}
                height={height}
                headerHeight={50}
                rowHeight={getRowHeight}
                rowCount={sortedRequestsByDate.length}
                rowGetter={({ index }) => sortedRequestsByDate[index]}
                rowClassName={({ index }) => {
                  const row = sortedRequestsByDate[index];
                  const selectedClass = requestIdParam && row?.id === requestIdParam && 'selected';
                  const periodicityClass = isOddNumber(index) ? 'odd' : 'even';
                  return classNames(periodicityClass, selectedClass);
                }}
              >
                <Column
                  label="Request Id"
                  dataKey="compositeId"
                  width={250}
                  cellRenderer={({ cellData }) => validationLink(cellData, sortedRequestsByDate, userLevel)}
                />
                <Column
                  width={270}
                  label="Status"
                  dataKey="status"
                  cellRenderer={({ rowData }) => statusCellData(rowData)}
                />
                <Column
                  width={300}
                  label="Search Type"
                  dataKey="searchType"
                  cellDataGetter={({ rowData }) => getSearchType(rowData)}
                />
                <Column width={250} label="Donor Type" dataKey="donorType" />
                <Column
                  label="Run Date"
                  dataKey="runDate"
                  width={270}
                  cellRenderer={({ cellData }) => moment(cellData).format('DD-MM-YYYY')}
                />
                <Column label="Run By" dataKey="runBy" width={620} style={{ padding: '10px' }} />
                <Column
                  label="Int Registries"
                  dataKey="registries"
                  width={450}
                  cellRenderer={({ cellData }) => cellData}
                />
                <Column
                  width={400}
                  label="Algorithms Used"
                  dataKey="algorithm"
                  cellRenderer={({ cellData }) => cellData}
                />
                <Column
                  width={500}
                  label="Actions"
                  dataKey="resultsLinkData"
                  cellRenderer={({ cellData, rowData }) => resultPageLink(cellData, rowData)}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }

  handleActivateRepeatSearchButtonClick = (requestId: string, patientId: string) => {
    this.setState(
      (prevState) => ({
        isRepeatSearchActivating: {
          ...prevState.isRepeatSearchActivating,
          [requestId]: true,
        },
      }),
      () => {
        const { activateRepeatSearch } = this.props;
        activateRepeatSearch(requestId, patientId);
      }
    );
  };

  handleReactivateDeactivatedRepeatSearchButtonClick = (requestId: string, patientId: string) => {
    this.setState(
      (prevState) => ({
        isReactivatingRepeatSearch: {
          ...prevState.isReactivatingRepeatSearch,
          [requestId]: true,
        },
      }),
      () => {
        const { reActivateRepeatSearch } = this.props;
        reActivateRepeatSearch(requestId, patientId);
      }
    );
  };

  handleRetryFailedSearchButtonClick = (
    requestId: string,
    patientId: string,
    donorType: string,
    isFirstRepeatSearch: boolean
  ) => {
    this.setState((prevState) => ({
      isRetryingFailedSearch: {
        ...prevState.isRetryingFailedSearch,
        [`${requestId}-${donorType}`]: true,
      },
    }));
    const { retryFailedOriginalSearch, retryFailedRepeatSearch } = this.props;
    if (isFirstRepeatSearch) {
      retryFailedRepeatSearch(requestId, patientId, donorType);
    } else {
      retryFailedOriginalSearch(requestId, patientId, donorType);
    }
  };

  pollForSearchResults() {
    const { searchRequests, fetchSearchRequest, individualSearchRequestsBeingFetched } = this.props;
    if (individualSearchRequestsBeingFetched.some((x) => x)) {
      this.setState({ autoRequestFetchingStopped: true });
      return;
    }
    const mappedSearches = searchRequests ? mapAllSearches(searchRequests) : [];
    const pendingSearches = mappedSearches.filter(
      (s) => s.resultsLinkData.resultSetStatus === resultSetTypes.initiated
    );

    if (pendingSearches.some((x) => x)) {
      pendingSearches.forEach((s) => {
        fetchSearchRequest(s.id);
      });
      this.pollForSearchResultsTimeOut = setTimeout(this.pollForSearchResults.bind(this), 5000);
    }
  }
}

export default withRouter(connector(ResultsTable));
